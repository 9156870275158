import getUnreadMessages from '../../hooks/getUnreadMessages';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

export default function NotificationCenter() {
    const navigate = useNavigate();

    const { isLoading, error, data, refetch }  = useQuery({ 
        queryKey: ['notifications'], 
        queryFn: async() => await getUnreadMessages(),
        refetchInterval: 30000
    });

    return <UncontrolledDropdown>
        <DropdownToggle className='logout bg-white position-relative'>
            <FontAwesomeIcon icon={faBell}  className="text-primary position-relative" />
            {!isLoading && data.length > 0 &&
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {data.length}
                    <span className="visually-hidden">Benachrichtigungen</span>
                    </span>
            }
        </DropdownToggle>
        <DropdownMenu>
            {isLoading ?
                <DropdownItem text>
                    Benachrichtigungen werden geladen
                </DropdownItem>:
                data.length > 0 ?
                    data.map((notification, key) => {
                        return <DropdownItem key={key} onClick={() => {
                            navigate(notification.link)
                        }}>
                            <p className='mb-0'>
                                <strong>{notification.title}</strong><br/>
                                {notification.message}
                            </p>
                        </DropdownItem>
                    }):
                    <DropdownItem text>
                        Aktuell keine Benachrichtigungen
                    </DropdownItem>
            }
        </DropdownMenu>
    </UncontrolledDropdown>
}