import { faCloudArrowDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { UncontrolledTooltip } from "reactstrap";
import getInvoicePDF from "../../hooks/getInvoicePDF";
import getInvoices from "../../hooks/getInvoices";

export default function Table(){

    const { isLoading, error, data }  = useQuery({ queryKey: ['invoices'], queryFn: async() => await getInvoices()})

    const getPDF = (id) => {
        getInvoicePDF(id).then((response) => {
            if(response.status)
                window.open(response.pdf,'__blank').focus();
        });
    };

    if(isLoading)
        return <table className="table table-striped table-hover mt-3">
        <thead>
            <tr>
                <th scope="col" className="table-title">Nr.</th>
                <th scope="col" className="table-title">Abrechnungsperiode</th>
                <th scope="col" className="table-title">Datum</th>
                <th scope="col" className="table-title">Preis</th>
                <th scope="col" className="table-title"></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
            </tr>
            <tr>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
            </tr>
            <tr>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
                <td><div className="placeholder-loading"></div></td>
            </tr>
        </tbody>
    </table>;

    // failed loading invoices
    if(error)
        return <p>Leider gab es einen Fehler beim Laden der Rechnungen. Bitte wenden Sie sich an den Support.</p>;

    return data.length ?
        <table className="table table-striped table-hover mt-3">
            <thead>
                <tr>
                    <th scope="col" className="table-title">Nr.</th>
                    <th scope="col" className="table-title">Abrechnungsperiode</th>
                    <th scope="col" className="table-title">Datum</th>
                    <th scope="col" className="table-title">Preis</th>
                    <th scope="col" className="table-title"></th>
                </tr>
            </thead>
            <tbody>
                    {data.map((invoice, i) => {
                        var options = { year: 'numeric', month: '2-digit', day: '2-digit' };
                        return <tr key={invoice.id}>
                            <td>{invoice.invoicenumber}</td>
                            <td>{invoice.timespan}</td>
                            <td>{(new Date(invoice.created_at)).toLocaleDateString('de-DE',options)}</td>
                            <td>{invoice.price}</td>
                            <td>
                                <button onClick={() => getPDF(invoice.id)} className="ms-2 btn p-0 table-link">
                                    <FontAwesomeIcon icon={faCloudArrowDown} className="fs-5 me-2" id={"invoice-"+invoice.id+"-download"}/>
                                    <UncontrolledTooltip placement={'top'} target={"invoice-"+invoice.id+"-download"}>
                                    Rechung downloaden
                                    </UncontrolledTooltip>
                                </button>
                            </td>
                        </tr>
                    })
                }
            </tbody>
        </table>:
        <p className="d-block text-center text-muted"><i>Keine Rechungen vorhanden</i></p>;
}