import axios from "axios";

export async function me(){
    return await axios.get('/me').then((response) => {
        if(response.status === 200)
            return {status:true, profile: response.data};

        return {status: false, message: 'Konnte nicht geladen werden'};
    }).catch((error) => {
        if(error.code === 'ERR_NETWORK')
        {
            return {status: false, message: "Netzwerk Probleme"};
        }else{
            if(error.response.status === 401)
            {
                return {status: false, message: error.response.data.error};
            }else{
                return {status: false, message: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support"};
            }
        }
    });
}