import axios from "axios";

export async function uploadFile(link, file, type, onUploadProgress){
    return await axios({
        url: link,
        data:file,
        method: "put",
        headers: {
            "Access-Control-Allow-Origin": '*',
            "Content-Type":type,
            "x-amz-acl": "public-read"
        },
        onUploadProgress,
        transformRequest: [
            (data, headers) => {
                if(headers?.common?.Authorization)
                delete headers.common.Authorization;
                return data;
            }
        ]
    }).then((response) => { 
        if(response.status === 201 || response.status === 200)
            return true

        return false;

    }).catch(() => {
        return false;
    });
}