import axios from "axios";

export default async function saveRegions(link, regions){
    return await axios.put(link+'/regions', regions)
    .then((response) => {
        if(response.status === 200)
            return {status:true};

        return {status: false, error: [{ type: 'error', text: response.message }]}
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
            return {status: false, error: [{ type: 'error', text: 'Netzwerk Probleme' }]}
            
        if(error.response.status === 401)
            return {status: false, error: [{ type: 'error', text: error.response.data.error }]}

        if(error.response.status === 422){
                let errors = error.response.data.errors;
                let messages = [];
                for (var key in errors) {
                    for (var message of errors[key]) {
                        messages.push({ type: 'error', text: message });
                    }
                }
            return {status: false, error: messages};
        }

        return {status: false, error: [{ type: 'error', text: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support" }]};
    });
}