import { Outlet } from "react-router-dom";
import katzenscellet from "./../assets/images/Katzenskelett_transparent.png"

export default function Users() {

    return (
        <div className="inside position-relative">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Benutzer</h1>
                                    <p>Die Benutzerverwaltung Ihrer Klinik/Praxis.</p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenscellet} alt="Katzen-Röntgen" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4"><Outlet /></div>
            <div className="bg-footer"></div>
        </div>
    );
}