import axios from "axios";

export async function getPasswordResetLink(email){
    return await axios.post('/password-reset/link', {
            email: email
    }).then(function (response) {
        if(response.status === 200)
            return true;

        return response.message;
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
            return "Netzwerk Probleme";

        if(error.response.status === 401)
            return error.response.data.error;

        if(error.response.status === 400)
            return "Falsche Anmeldedaten angegeben";
                
        return "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support";
    });
}