import axios from "axios";

export async function register(company, client){
    return await axios.post('/registration', {
        company: company,
        client: client,
    }).then(function (response) {
        if (response.status === 201 || response.status === 204)
            return true;

        return [response.message];
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
            return [{type: 'error', text: 'Netzwerk Probleme'}];

        if(error.response.status === 401)
            return [{type: 'error', text: error.response.data.error}];
        if(error.response.status === 422){
            let errors = error.response.data.errors;
            let messages = [];
            for(var key in errors)
            {
                for(var message of errors[key])
                {
                    messages.push({type: 'error', text: message});
                }
            }
            return messages;
        }

        return [{type: 'error', text: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support"}];
    });
}
