import { faCircleLeft, faCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Details from "../Order/Details";

export default function Gone({order}){

    return <>
        <h2>Anfrage bereits in Bearbeitung</h2>
        <p>Die Anfrage ist in der Zwischenzeit von einem Mitarbeiter übernommen worden und ist jetzt als Auftrag {order} in Bearbeitung.<br/>
        Sie können uns bezüglich dieses Auftrags über die Kommunikationsseite kontaktieren.</p>
        <Link to={"/anfragen"} title="Zur Kommunikationsseite" className="btn grey-rounded-link rounded-pill mb-4 me-3">
            <FontAwesomeIcon icon={faCircleLeft} className="me-2"/>
            Zur Übersicht
        </Link>
        <Link to={"/anfragen/auftrag/"+order+"/kommunikation"} title="Zur Kommunikationsseite" className="btn green-rounded-link rounded-pill mb-4">
            Nachricht senden
            <FontAwesomeIcon icon={faCircleRight} className="ms-2"/>
        </Link>
        <Details id={order}/>
    </>
}