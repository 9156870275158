import axios from "axios";

export async function changePassword(email, token, password, password_confirmation){
    return await axios.post('/password-reset', {
            email: email,
            token: token,
            password: password,
            password_confirmation: password_confirmation
        }).then(function (response) {
            if(response.status === 200)
                return true;
            
            return response.message;
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
            return ["Netzwerk Probleme"];

        if(error.response.status === 401)
            return error.response.data.error;

        if(error.response.status === 422){
            let errors = error.response.data.errors;
            let messages = [];
            for(var key in errors)
            {
                for(var message of errors[key])
                {
                    messages.push(message);
                }
            }
            return messages;
        }
    
        
        if(error.response.status === 400)
            return ["Ungültiger Link oder ungültige Benutzerdaten."];

        return ["Fehler ist aufgetreten. Bitte kontaktieren Sie den Support"];
    });
}