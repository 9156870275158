import { Link } from "react-router-dom"
import { Loading } from "../components/App/Loading";
import { useAuth } from "../components/Auth/AuthProvider";
import katzenscellet from "./../assets/images/Katzenskelett_transparent.png"

export default function Dashboard() {
    const auth = useAuth();

    return (
        <div className="inside">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Mein Bereich</h1>
                                    <p>Anfragen, Rechnungen, Benutzer – erstellen und verwalten</p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenscellet} alt="Katzen-Röntgen" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-4 my-lg-5">
                <div className="row">
                    <div className="col-12 col-lg-6 px-2 pe-lg-3">
                        <div className="bg-lightgrey border-rounded p-4 h-100 font-black">
                            <h3>Anfrage</h3>
                            <p>Erstellen Sie eine neue Anfrage. Füllen Sie Schritt für Schritt das Anfrageformular aus: Patientendaten einfügen, Verfahren und Körperregion auswählen, Anamnese und Ergebnisse der klinischen Untersuchung eintragen, Bildmaterial direkt hochladen oder über den Server senden. Im letzten Schritt können Sie noch einmal alle gemachten Angaben und Daten überprüfen. Wenn alles stimmt, schicken Sie Ihre Anfrage ab und erhalten innerhalb von 24 Stunden nach Eingang aller Unterlagen den Befund. </p>
                            <Link to="/anfragen/neu" title="neue Anfrage" className="green-rounded-link py-1 px-3 me-sm-3 rounded-pill">neue Anfrage<i className="icon-plus-circled i-left"></i></Link>
                            <Link to="/anfragen" title="zur Übersicht" className="white-rounded-link py-1 px-3 mt-3 mt-sm-0 rounded-pill">zur Übersicht</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0 px-2 ps-lg-3">
                        <div className="bg-lightgrey border-rounded h-100 p-4 font-black">
                            {auth.hasRight() || auth.hasRole('ACCOUNTING') ?
                            <>
                                <h3>Rechnungen</h3>
                                <p>Hier finden Sie eine Übersicht über alle Ihre Rechnungen.</p>
                                <Link to="/rechnungen" title="zu den Rechnungen" className="white-rounded-link py-1 px-3 rounded-pill">zu den Rechnungen</Link>
                            </> :
                                (auth?.profile?.user ?
                                <>
                                    <h3>{auth.profile.user.username}</h3>
                                    <p>{auth.profile.user.firstname} {auth.profile.user.lastname}<br />
                                        <small className='fw-bold'>E-MAIL:</small> {auth.profile.user.email}<br />
                                        <small className='fw-bold'>TELEFONNUMMER:</small> {auth.profile.user.phone ? auth.profile.user.phone : '-'}</p>
                                    <Link to="/profil" title="Mein Profil" className="white-rounded-link py-1 px-3 rounded-pill">Mein Profil</Link>
                                </> :
                                <Loading />)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid my-4 overflow-hidden">
                <div className="row px-2 px-sm-4 px-lg-0">
                    <div className="col-12 col-md-10 offset-md-1 offset-lg-0 col-lg-6 px-0">
                        <div className="bg-grey-left px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-10 pt-4 pb-4 col-xxl-9 offset-lg-2 offset-xxl-3 produktreihe pt-4 ps-3 ps-lg-0 pe-3 pe-lg-5 pb-0">
                                {auth.hasRight() ?
                                <>
                                    <h3>Benutzer anlegen</h3>
                                    <p>Legen Sie weitere Benutzer an, die Anfragen stellen und Befunde einsehen können.</p>
                                    <Link to="/benutzer/neu" title="Neue Anlage" className="white-rounded-link py-1 px-3 rounded-pill">neue Anlage</Link>
                                </>:
                                <>
                                    <h3>Öffnungszeiten</h3>
                                    <p>Unsere Öffnungszeiten sind<br/>
                                        Montag bis Freitag 09:00 bis 17:00 Uhr<br />
                                        In dieser Zeit erhalten Sie innerhalb von 24 Stunden nach Erhalt aller erforderlichen Unterlagen Ihren Befund.</p>
                                </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 offset-lg-0 col-lg-6 px-0 mt-4 mt-lg-0">
                        <div className="bg-grey-right px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 pt-4 pb-4 col-lg-10 col-xxl-9 produktreihe pt-4 pe-3 pe-lg-0 ps-3 ps-lg-5 pb-0">
                                <h3>Fragen und Antworten</h3>
                                <p>Sie haben Fragen zu unserem Service? Hier finden Sie Antworten zu verschiedenen Fragen.<br/>
Ihre Frage ist nicht dabei, dann kontaktieren Sie uns unter kontakt@vetradiologie.de</p>
                                <Link to="/faq" title="Zu den FAQ" className="white-rounded-link py-1 px-3 rounded-pill">zu den FAQ</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5 mb-5 d-none">
                <div className="row">
                    <h2 className="font-green pb-2">Ihre letzten Anfragen</h2>
                    <div className="col-12 col-lg-6 px-2">
                        <div className="bg-lightgrey font-black border-rounded p-4">
                            <h3>15.05.2022 | CT</h3>
                            <div className="row pt-3">
                                <div className="col-3 font-bezeichnung">Besitzer:</div>
                                <div className="col-auto">Felix Bauer</div>
                            </div>
                            <div className="row">
                                <div className="col-3 font-bezeichnung">Haustier:</div>
                                <div className="col-auto">Brady, Hund, 3 Jahre, männlich</div>
                            </div>
                            <div className="row pb-3">
                                <div className="col-3 font-bezeichnung">Kennung:</div>
                                <div className="col-auto">4564</div>
                            </div>
                            <div className="progress bg-grey my-3 mb-4">
                                <div className="progress-bar progress-bearbeitung" role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100">in Bearbeitung</div>
                            </div>
                            <Link to="/" title="neue Kommunikation" className="green-rounded-link py-1 px-3 rounded-pill">Kommunikation<i className="icon-chatbubbles i-left"></i></Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0 px-2">
                        <div className="bg-lightgrey font-black border-rounded p-4">
                            <h3>10.05.2022 | RÖNTGEN, MRT, CT</h3>
                            <div className="row pt-3">
                                <div className="col-3 font-bezeichnung">Besitzer:</div>
                                <div className="col-auto">Marianne Schmidt</div>
                            </div>
                            <div className="row">
                                <div className="col-3 font-bezeichnung">Haustier:</div>
                                <div className="col-auto">Heidi, Hund, 4 Jahre, weiblich</div>
                            </div>
                            <div className="row pb-3">
                                <div className="col-3 font-bezeichnung">Kennung:</div>
                                <div className="col-auto">8453</div>
                            </div>
                            <div className="progress bg-grey my-3 mb-4">
                                <div className="progress-bar progress-befund" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">Befund erstellt</div>
                            </div>
                            <Link to="/" title="neue Kommunikation" className="green-rounded-link py-1 px-3 rounded-pill">Kommunikation<i className="icon-chatbubbles i-left"></i></Link>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 col-lg-6 px-2">
                        <div className="bg-lightgrey font-black border-rounded p-4">
                            <h3>05.05.2022 | CT</h3>
                            <div className="row pt-3">
                                <div className="col-3 font-bezeichnung">Besitzer:</div>
                                <div className="col-auto">Felix Bauer</div>
                            </div>
                            <div className="row">
                                <div className="col-3 font-bezeichnung">Haustier:</div>
                                <div className="col-auto">Brady, Hund, 3 Jahre, männlich</div>
                            </div>
                            <div className="row pb-3">
                                <div className="col-3 font-bezeichnung">Kennung:</div>
                                <div className="col-auto">4564</div>
                            </div>
                            <div className="progress bg-grey my-3 mb-4">
                                <div className="progress-bar progress-abgeschlossen" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">abgeschlossen</div>
                            </div>
                            <Link to="/" title="neue Kommunikation" className="green-rounded-link py-1 px-3 rounded-pill">Kommunikation<i className="icon-chatbubbles i-left"></i></Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0 px-2">
                        <div className="bg-lightgrey font-black border-rounded p-4">
                            <h3>28.04.2022 | RÖNTGEN, MRT, CT</h3>
                            <div className="row pt-3">
                                <div className="col-3 font-bezeichnung">Besitzer:</div>
                                <div className="col-auto">Marianne Schmidt</div>
                            </div>
                            <div className="row">
                                <div className="col-3 font-bezeichnung">Haustier:</div>
                                <div className="col-auto">Heidi, Hund, 4 Jahre, weiblich</div>
                            </div>
                            <div className="row pb-3">
                                <div className="col-3 font-bezeichnung">Kennung:</div>
                                <div className="col-auto">8453</div>
                            </div>
                            <div className="progress bg-grey my-3 mb-4">
                                <div className="progress-bar progress-offen" role="progressbar" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">offen</div>
                            </div>
                            <Link to="/" title="neue Kommunikation" className="green-rounded-link py-1 px-3 rounded-pill">Kommunikation<i className="icon-chatbubbles i-left"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}