export default function Status (props){
    const steps = [
        {nr: 1 , title: "Angaben zu Tier & Besitzer"},
        {nr: 2 , title: "Körperregion"},
        {nr: 3 , title: "Klinische Angaben"},
        {nr: 4 , title: "Ihre Dateien"},
        {nr: 5, title: "Abgeschlossen"},
    ]

    return  <ul id="progressbar">
                {steps.map((step, i) => {
                    return <li key={step.nr} id={"step0"+step.nr} className={step.nr === props.step ? "active" : ""}>{step.title}</li>
                })}
            </ul>
}