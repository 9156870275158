import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import getRequests from "../../hooks/getRequests";
import {deleteRequest as delRequest } from "../../hooks/deleteRequest";
import {getMedicalReport as medicalReport } from "../../hooks/getMedicalReport";
import RowDraft from "./RowDraft";
import RowInquiry from "./RowInquiry";
import RowOrder from "./RowOrder";
import { Modal, ModalBody, ModalHeader, Toast, ToastBody, ToastHeader } from "reactstrap";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faRefresh, faTimes, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Link } from "react-router-dom";
import Details from "../Order/Details";

export default function Table() {
    const [message, setMessage] = useState({});
    const [search, setSearch] = useState("");
    const [order, setOrder] = useState(null);
    const [detailsVisible, isDetailsVisible] = useState(false);
    const [filter, setFilter] = useState({search: ""});
    const queryClient = useQueryClient();

    // get all kind of requests (drafts/inquiries/orders)
    const { isLoading, error, data, refetch, dataUpdatedAt, isFetching }  = useQuery({ queryKey: ['requests', filter], queryFn: async() => await getRequests(filter)})

    // delete a draft or inquiry
    const deleteRequest = useMutation({mutationFn: (id) => {
            return delRequest(id);
        },
        onSuccess: (data,id) => {
            setMessage({isOpen: true ,title:'Anfrage Nr.'+id+' gelöscht', description: 'Anfrage Nr.'+id+' wurde erfolgreich gelöscht'});
            queryClient.invalidateQueries({ queryKey: ['requests'] });
            setTimeout(() => {
                setMessage({...message, isOpen:false });
            }, 4000);
        }
    });

    // get the medical Report 
    const getMedicalReport = (id) =>{
        medicalReport(id).then((response) => {
            if(response.status)
                window.open(response.medical_report, '__blank').focus();
        });
    }

    const showOrderDetails = (order) => {
        setOrder(order);
        isDetailsVisible(true);
    }

    //  loading all requests failed
    if(error)
        return <p>Leider gab es einen Fehler beim Laden der Anfragen. Bitte wenden Sie sich an den support.</p>

    // is loading
    if(isLoading)
        return <table className="table table-striped table-hover mt-3">
            <thead>
                <tr>
                    <th scope="col" className="table-title">Nr.</th>
                    <th scope="col" className="table-title">Besitzer/Tiername</th>
                    <th scope="col" className="table-title">Datum</th>
                    <th scope="col" className="table-title text-center">Status</th>
                    <th scope="col" className="table-title">Preis*</th>
                    <th scope="col" className="table-title"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                </tr>
                <tr>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                </tr>
                <tr>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                    <td><div className="placeholder-loading"></div></td>
                </tr>
            </tbody>
        </table>

    // overview
    return <>
    <Modal isOpen={detailsVisible} toggle={() => isDetailsVisible(false)} size="xl" className="position-relative">
        <ModalHeader toggle={() => isDetailsVisible(false)} className="border-0 position-absolute top-0 end-0" style={{zIndex: '10'}}/>
        <ModalBody className="p-0">
            <Details id={order} />
        </ModalBody>
    </Modal>

    <Toast isOpen={message && message.isOpen ? true : false} className='position-absolute' style={{top: '10px', left: "calc(50% - 175px)"}}>
        <ToastHeader icon={<i className="icon icon-check text-success"></i>}>
            {message?.title}
        </ToastHeader>
        <ToastBody>
            {message?.description}
        </ToastBody>
    </Toast>
    <div className="d-flex flex-row align-items-center justify-content-between">
        <Link to="/anfragen/neu" title="Neue Anfrage hinzufügen" className="btn btn-outline-primary py-1 px-3 me-sm-3">
            <FontAwesomeIcon icon={faPlus} className='me-2' />
            Neue Anfrage
        </Link>
        <div className="input-group w-auto" style={{cursor: 'pointer'}}>
            <input type='text' className="form-control" placeholder="Suche Besitzer/Tiername..." value={search} onChange={(el) => setSearch(el.target.value) }></input>
            {filter?.search?.length > 0 && <button className="btn btn-outline-primary" onClick={() => { setSearch(''); setFilter({...filter, search: ""})}}>
            <FontAwesomeIcon icon={faTimes} />
            </button>}
            <button className="btn btn-primary" type="button" disabled={search.length <= 0 || isFetching} onClick={() => setFilter({...filter, search: search})}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
        </div>
        <div className="d-flex align-items-end">
            <p className="mb-0">
                {isFetching ?
                'Aktualisiert...' :
                dataUpdatedAt && new Date(dataUpdatedAt).toLocaleTimeString('de-DE',{ hour: '2-digit', minute: '2-digit', second: '2-digit'})}
            </p>
            <button type="button" className="btn btn-outline-primary ms-2" onClick={refetch}>
                <FontAwesomeIcon icon={faRefresh} className={isFetching ? 'fa-spin' : ''}/>
            </button>
        </div>
    </div>
    {data.length > 0 ?
    <table className="table table-striped table-hover mt-3">
        <thead>
            <tr>
                <th scope="col" className="table-title">Nr.</th>
                <th scope="col" className="table-title">Besitzer</th>
                <th scope="col" className="table-title">Tiername</th>
                <th scope="col" className="table-title">Erstellt</th>
                <th scope="col" className="table-title">Aktualisiert</th>
                <th scope="col" className="table-title text-center">Status</th>
                <th scope="col" className="table-title text-end">Preis*</th>
                <th scope="col" className="table-title text-end">Optionen</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => {
                if(row.type ==='draft')
                    return <RowDraft key={i} draft={row} deleteRequest={deleteRequest}/>
                if(row.type ==='inquiry')
                    return <RowInquiry key={i} inquiry={row} deleteRequest={deleteRequest} />
                if(row.type ==='order')
                    return <RowOrder key={i} order={row} getMedicalReport={getMedicalReport} showOrderDetails={showOrderDetails} />
            })}
        </tbody>
    </table>:
    <p className="d-block text-muted text-center mt-4 pt-3 border-top"><i>Noch keine Anfrage gestellt</i></p>}
    </>
}