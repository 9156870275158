import axios from "axios";

export async function login(email, password){
    return await axios.post('/login', {
        email: email,
        password: password,
    }).then(function (response) {
        if (response.status === 204)
            return {status: true};

        return {status: false, message: response.message};
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
        {
            return {status: false, message: "Netzwerk Probleme"};
        }else{
            if(error.response.status === 401)
            {
                return {status: false, message: error.response.data.error};
            }else{
                return {status: false, message: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support"};
            }
        }
    });
}