import axios from "axios";

export async function notifyCrm(url, filepath){
    return await axios.post(url+'/attachments/uploads/done',{filepath: filepath}).then((response) => {
        if(response.status === 201)
            return response.data;

        return false;
    }).catch(() => {
        return false;
    });
}