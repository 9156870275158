import axios from "axios";

export async function getMedicalReport(id){
    return await axios.get('/me/orders/'+id+'/medical_report').then((response) => {
        if(response.status === 200) 
            return {status: true, medical_report: response.data}

        return {status: false, message: 'Keine Daten gefunden'};
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
        {
            return {status: false, message: "Netzwerk Probleme"};
        }else{
            if(error.response.status === 401)
            {
                return {status: false, message: error.response.data.error};
            }else{
                return {status: false, message: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support"};
            }
        }
    });
}