import axios from "axios";

export default async function getInvoicePDF(id){
    return await axios.get('/me/invoices/'+id+'/pdf').then((response) => {
        if(response.status === 200)
            return {status: true, pdf: response.data}

        return {status: false};
    }).catch(() => {
        return {status:false};
    });
}