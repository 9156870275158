import { useEffect, useRef, useState } from "react";
import deleteUpload from "../../../hooks/deleteUpload";
import getAllowedFileExtensions from "../../../hooks/getAllowedFileExtensions";
import getAttachments from "../../../hooks/getAttachments";
import { getUploadLinks } from "../../../hooks/getUploadLinks";
import { notifyCrm } from "../../../hooks/notifyCrm";
import { uploadFile } from "../../../hooks/uploadFile";
import { Loading } from "../../App/Loading";;

export default function Upload(props) {
    const [messages, setMessages] = useState([]);
    const [loading, isLoading] = useState(true);
    const [uploads, setUploads] = useState([]);
    const [uploading, isUploading] = useState(null);
    const [maxUploads, setMaxUploads] = useState(10);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [status, setStatus] = useState(null);
    const firstRender = useRef(true);
    const [dragActive, setDragActive] = useState(false);

    useEffect(() => {
        if (!uploads.length && firstRender.current) {
            firstRender.current = false;
            getAttachments(props.url).then((attachments) => {
                if (attachments && attachments.length) {
                    setUploads(attachments);
                    setMaxUploads(maxUploads - attachments.length);
                    props.hasUploads(true);
                }
                isLoading(false);
            })
        }

        if (!firstRender.current)
            setMaxUploads(10 - uploads.length);
    }, [uploads.length]);

    const fileSelector = (event) => {
        let files = [];
        let newstatus = [];
        let counter = 0;
        if (event.target.files.length > 0) {
            for (const file of event.target.files) {
                counter++;
                files.push(file);
                if (counter === maxUploads)
                    break;
            }
            newstatus = [...new Array(files.length)].map(x => 0);
        }

        setStatus(newstatus);
        setSelectedFiles(files);
        setIsFilePicked(true);
    };

    const upload = async (e) => {
        props.isUploading(true);
        isUploading(true);
        setMessages([]);

        // get Upload Links for each selected file
        await getUploadLinks(props.url, selectedFiles).then(async (links) => {
            if (links.length <= 0)
                throw Error('Für die Datei/en konnte kein "Upload-Link" erstellt werden. Der Systemadministrator ist verständigt. Bitte kontaktieren Sie den Support um alternativ die Dateien zu übermitteln');

            await Promise.all(links.map(async (entry) => {
                await uploadFile(entry.link, selectedFiles[entry.key], selectedFiles[entry.key].type, (progressEvent) => {
                    let progress = Math.round((100 * progressEvent.loaded) / progressEvent.total);
                    setStatus(status.map((state, i) => {
                        if (i === entry.key)
                            return progress;
                        return state;
                    }));
                }).then((worked) => {
                    if (worked) {
                        // notify the crm if the upload worked
                        notifyCrm(props.url, entry.filepath)
                            .then((worked) => {
                                if (worked !== false) {
                                    if (!uploads.length)
                                        props.hasUploads(true);
                                    setUploads(uploads => [...uploads, worked]);
                                    setSelectedFiles(null);
                                } else {
                                    throw Error('Mitarbeiter konnte nicht verständigt werden über den Upload der Datei "' + entry.key + '". Bitte kontaktieren Sie den Support, damit diese Datei nicht bei der Bearbeitung übersehen wird');
                                }
                            }).catch((error) => {
                                setMessages([...messages, error.message]);
                            });
                    } else {
                        throw Error('Datei  Nr.' + (entry.key + 1) + ' konnte nicht hochgeladen werden');
                    }
                }).catch((error) => {
                    setMessages([...messages, error.message]);
                });
            })).finally(() => {
                props.isUploading(false);
                isUploading(false);
            });
        }).catch((error) => {
            setMessages([error.message]);
            isUploading(false);
            props.isUploading(false);
        });
    }

    const deleteFile = (id) => {
        setUploads(current => current.map((entry) => {
            entry.deleting = entry.deleting || entry.id === id;
            return entry;
        }));

        deleteUpload(props.url, id).then((status) => {
            if (status) {
                let filtered = uploads.filter((entry) => {
                    return entry.id !== id;
                });
                setUploads(filtered);
                if (!filtered.length)
                    props.hasUploads(false);
            }
        });
    }

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            let files = [];
            let newstatus = [];
            let counter = 0;

            for (const file of e.dataTransfer.files) {
                counter++;
                files.push(file);
                if (counter === maxUploads)
                    break;
            }
            newstatus = [...new Array(files.length)].map(x => 0);

            setStatus(newstatus);
            setSelectedFiles(files);
            setIsFilePicked(true);
        }
      };

    return loading ?
        <Loading /> :
        <>
            <h4>Dateiupload ({10 - maxUploads}/10)</h4>
            <p>Wählen Sie die Dateien aus, die hochgeladen werden sollen. Wenn Sie Serienbilder oder mehrere Bilder (&gt;5) hochladen wollen, komprimieren Sie bitte die Dateien und speichern Sie sie in einer Zip- oder Rar-Datei.</p>
            {messages.length > 0 &&
                <div className="messages d-block">
                    {messages.map((message, i) => {
                        return <p key={i} className="error">{message}</p>
                    })}
                </div>}
            {maxUploads === 0 ?
                <p>Maximal Anzahl an Uploads erreicht</p> :
                <div id="dropArea" className={dragActive ? 'drop-hover': ''} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
                    {!uploading &&
                        <>
                            <input type="file" id="fileupload" name="fileupload" className="d-none" accept={getAllowedFileExtensions(true)} onChange={fileSelector} multiple />
                            <label htmlFor="fileupload"  className="select-files my-1 text-center">
                                <i className="icon-plus-circled i-right"></i>Dateien auswählen
                            </label>
                        </>
                    }
                    {isFilePicked && selectedFiles && selectedFiles.map((selectedFile, key) => {
                        return <div key={key} className="d-block link-upload link-upload-cancel position-relative my-1 p-2" data-status="0">
                            <i className="icon-pdf i-right d-none d-sm-inline"></i>{selectedFile.name} ({
                                (selectedFile.size > 1000000000 ?
                                    Number((selectedFile.size / 1000000000).toFixed(2)) + " GB" :
                                    (selectedFile.size > 1000000 ?
                                        Number((selectedFile.size / 1000000).toFixed(2)) + " MB" :
                                        (selectedFile.size > 1000 ?
                                            Number((selectedFile.size / 1000).toFixed(2)) + " KB" :
                                            selectedFile.size + " Bytes")
                                    ))})
                            <div className="upload-status" style={{ width: status[key] + '%' }}></div>
                        </div>
                    })}
                    <button type="button" disabled={uploading === true || !selectedFiles || !selectedFiles.length} onClick={upload} className="link-upload link bg-grey d-block w-100 p-2 text-center border-0">
                        Hochladen{uploading === true ? <span className="submitted"></span> : (uploading === false ? <i className="fas fa-check text-success"></i> : '')}
                    </button>
                </div>}
            {uploads && uploads.map((val, key) => {
                return <div key={key} className="d-flex flex-row align-items-center link-upload-cancel my-1 p-2">
                    <i className="icon-pdf i-right d-none d-sm-inline"></i>{val.basename}
                    {val.deleting === true ?
                        <span className="submitted ms-auto me-3"></span> :
                        <button type="button" onClick={() => deleteFile(val.id)} title="Dateien entfernen" className="btn py-0 ms-auto">
                            <i className="icon-cancel"></i>
                        </button>
                    }
                </div>
            })}
        </>
}