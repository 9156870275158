import axios from "axios";

export async function getUploadLinks(url, files){
    return await axios.post(url+"/attachments/uploads", {attachments: files.map((file,key) => {return {key:  key, name: file.name};})}).then((response) => {
        if(response.status === 200)
            return response.data;

        return [];
    }).catch(() => {
        return [];
    });
}