import katzenskellet from './../../assets/images/Katzenskelett_transparent.png'
import { Link, useRouteError } from "react-router-dom";
import Gone from "./Gone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleLeft, faEnvelope } from '@fortawesome/pro-solid-svg-icons';

export function Error(){
    let error = useRouteError();

    let headline = "Fehler";
    let content = <div>
        <p>Ein Fehler ist aufgetreten. Der Support ist verständigt. Wenn Sie Rückfragen haben oder wissen möchten was am Besten zu tun ist kontakieren Sie uns:</p>
        <a href="mailto:kontakt@vetradiologie.de" >E-Mail an kontakt@vetradiologie.de</a>
    </div>;


    if(error?.response?.status === 410){
        headline = "Anfrage in Bearbeitung";
        content = <Gone order={error.response.data.order } />;
    }

    if(error?.response?.status === 404){
        headline = "Anfragen nicht gefunden";
        content = <div>
                    <p>Die Anfrage konnte nicht gefunden werden. Vielleicht wurde sie in der Zwischenzeit storniert?<br/>
                    Bei Rückfragen kontakieren Sie uns.</p>
                    <Link to={"/anfragen"} title="Zur Kommunikationsseite" className="btn grey-rounded-link rounded-pill mb-4 me-3">
                        <FontAwesomeIcon icon={faCircleLeft} className="me-2"/>
                        Zur Übersicht
                    </Link>
                    <a href="mailto:kontakt@vetradiologie.de" className="btn green-rounded-link rounded-pill mb-4" title='E-Mail verfassen' >
                        <FontAwesomeIcon icon={faEnvelope} className="me-2"/>
                        kontakt@vetradiologie.de
                    </a>
                </div>;
    }



    return <div className="inside">
                <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="container py-5">
                                <div className="row">
                                    <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                        <h1>{headline}</h1>
                                        <p></p>
                                    </div>
                                    <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                        <img src={katzenskellet} alt="Katzen-Röntgen" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container px-4">
                    <div className="row">
                        <div className="col-12 col-md-10 p-0 px-2 px-sm-0">{content}</div>
                    </div>
                </div>
                <div className="bg-footer"></div>
            </div>
}