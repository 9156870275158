import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"
import { changePassword } from "../../hooks/changePassword";

export const PasswordChange = () => {
    const [params] = useSearchParams();
    const [response, setResponse] = useState(null);
    const navigate = useNavigate();

    var paramToken = params.get('token');
    var paramEmail = params.get('email');

    return paramToken ?
    <Formik 
        initialValues={{
            email: paramEmail ?? '',
            token: paramToken,
            password: '',
            password_confirmation: ''
        }}
        validate={values => {
            const errors = {};
            if (!values.email) {
                errors.email = 'Pflichtfeld';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Ungültige E-Mail-Addresse';
            }
            
            if(!values.password)
                errors.password = 'Pflichtfeld';
            else if(values.password_confirmation && values.password !== values.password_confirmation)
                errors.password = 'Passwörter stimmen nicht überein';

            if(!values.password_confirmation)
                errors.password_confirmation = 'Pflichtfeld';
            else if(values.password_confirmation && values.password !== values.password_confirmation)
                errors.password_confirmation = 'Passwörter stimmen nicht überein';
    
            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            changePassword(values.email,
                            values.token, 
                            values.password, 
                            values.password_confirmation)
            .then((response) =>{
                if(response === true) {
                    setSubmitting(true);
                    navigate('/startseite');
                }else{
                    setSubmitting(false);
                    setResponse(response);
                }
            });
        }}>
    {(props) => (
        <Form>
            {response && 
                <div className='messages'>
                    {response.map((message, i) => {
                        return <span key={i} className='error mb-2'>{message}</span>
                    })}
            </div>}
                <div className="mb-3">
                        <Field type="email" name="email" placeholder="E-Mail-Adresse" className="form-control" required/>
                        <ErrorMessage name="email">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                    </div>
                    <div className="mb-3">
                        <Field type="password" name="password" placeholder="Neues Passwort" className="form-control" required/>
                        <ErrorMessage name="password">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                    </div>
                    <div className="mb-3">
                        <Field type="password" name="password_confirmation" placeholder="Neues Passwort bestätigen" className="form-control" required/>
                        <ErrorMessage name="password_confirmation">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                    </div>
                <div className='col-12 mt-3'>
                    <button type="submit" disabled={props.isSubmitting} className="py-1 px-3 rounded-pill btn-green mb-3">Passwort zurücksetzen</button>
                </div>
        </Form>
    )}
    </Formik>:
    <p class="error">Kein gültiger Addresse</p>;
    
}