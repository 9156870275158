import axios from "axios";

export default async function saveProfile(user){

    return await axios({
        method:'put',
        url: '/me',
        data: {
            title: user.title,
            address: user.address,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            company_position: user.company_position,
            ...(user.password && user.confirm_password &&  {
                password: user.password,
                password_confirmation: user.confirm_password
            })
        }
    }).then((response) => { 
        if(response.status === 204)
            return {status: true, mode:'updated'};
        
        return {status: false, error: {isOpen: true, type: 'error', title: 'Speichern fehlgeschlagen', description: response.message }}
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
            return {status: false, error: {isOpen: true, type: 'error', title: 'Speichern fehlgeschlagen', description: 'Netzwerk Probleme' }}
            
        if(error.response.status === 401)
            return {status: false, error: {isOpen: true, type: 'error', title: 'Speichern fehlgeschlagen', description: error.response.data.error }}

        if(error.response.status === 422){
                let errors = error.response.data.errors;
                let messages = [];
                for (var key in errors) {
                    for (var message of errors[key]) {
                        messages.push({ type: 'error', text: message });
                    }
                }
            return {status: false,  error: {isOpen: true, type: 'error', title: 'Speichern fehlgeschlagen', description: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support" }, messages: messages};
        }

        return {status: false, error: {isOpen: true, type: 'error', title: 'Speichern fehlgeschlagen', description: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support" }};
    });
}