import { faFlag } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import activateInquiry from "../../../hooks/activateInquiry";
import getTranslations from "../../../hooks/getTranslations";
import { Loading } from "../../App/Loading";;

export default function Overview(props) {
    let [loading, isLoading] = useState(true);
    let [activating, isActivating] = useState(false);
    let [translations, setTranslations] = useState(false);
    let [groupedRegions, setGroupedRegions] = useState({});

    useEffect(() => {
        if(loading) {
            getTranslations(props.url).then((response) => {
                if(response.status){
                    setTranslations(response.translations);
                    isLoading(false);
                }
            });
        }

        if((!groupedRegions || Object.keys(groupedRegions).length === 0) && props?.inquiry?.regions){
            setGroupedRegions(props.inquiry.regions.reduce((group, entry) => {
                const { procedure } = entry;
                group[procedure] = group[procedure] ?? [];
                group[procedure].push(entry);
                return group;
            }, {}));
        }
    }, [loading, groupedRegions, props.inquiry]);

    let activate = () => {
        isActivating(true);
        activateInquiry(props.url).then((status) => {
            if(status)
                props.finished();

             isActivating(false);

        }).catch(() => {
             isActivating(true);
        });
    }

    return loading ?
    <Loading />  :
    <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="bg-lightgrey font-black p-4 step01 h-100">
                        <h2 className="font-green pb-2"><i className="icon-paw"></i>1. Angaben zu Tier und Besitzer</h2>
                        <div className="row pt-1">
                            <div className="col-auto font-bezeichnung">Besitzer:</div>
                            <div className="col-auto">{props.inquiry.infos.pet_owner}</div>
                        </div>
                        <div className="row pt-0">
                            <div className="col-auto font-bezeichnung">Haustier:</div>
                            <div className="col-auto">{props.inquiry.infos.pet_name}, {translations[props.inquiry.infos.pet_species]}, {props.inquiry.infos.pet_birthyear}, {translations[props.inquiry.infos.pet_gender]}</div>
                        </div>
                        <div className="row pt-0">
                            <div className="col-auto font-bezeichnung">Kennung:</div>
                            <div className="col-auto">{props.inquiry.infos.custom_id ?? '-'}</div>
                        </div>
                        <div className="row pt-0">
                            <div className="col-auto font-bezeichnung">Tierarzt/Tierärztin:</div>
                            <div className="col-auto">{props.inquiry.infos.doctor ?? '-'}</div>
                        </div>
                        {props.inquiry.infos.importance &&
                        <div className="d-flex flex-row align-items-center mt-2">
                            <FontAwesomeIcon icon={faFlag}  className='text-danger me-2'/>
                            <div className="col-auto font-bezeichnung">Besondere Dringlichkeit</div>
                        </div>}
                        <div className="d-flex flex-row align-items-center justify-content-end pt-4">
                            <button onClick={() => props.goto(1)} title="Bearbeiten" className="d-block border-0 link-primary text-end p-0 m-0">Bearbeiten</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                    <div className="bg-lightgrey font-black p-4 step02 h-100">
                        <h2 className="font-green pb-2"><i className="icon-bone"></i>2. Körperregion</h2>
                        <div className="row">
                            {
                                Object.entries(groupedRegions).map((procedure,i)=>{
                                    return <div key={procedure[0]} className="col-12 col-sm-6 mb-3">
                                        <h4>{translations['diagnostic_imaging'][procedure[0]]} | Region</h4> 
                                        {Object.entries(procedure[1]).map((entry, i)=>{
                                            return <div key={entry[1].region} className="form-check pt-2">
                                            <input className="form-check-input opacity-100" type="checkbox" value="" id="flexCheckCheckedDisabled1" checked={true} disabled={true} />
                                            <label className="form-check-label opacity-100" htmlFor="flexCheckCheckedDisabled1">
                                                {translations['regions'][entry[1].region]} 
                                                {entry[1].position ? " | "+translations['positions'][entry[1].position]: ''}
                                            </label>
                                        </div>
                                        })}
                                    </div>
                                })
                            }
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-end pt-4">
                            <button onClick={() => props.goto(2)} title="Bearbeiten" className="d-block border-0 link-primary text-end p-0 m-0">Bearbeiten</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 col-lg-6">
                    <div className="bg-lightgrey font-black p-4 step03 h-100">
                        <h2 className="font-green pb-2"><i className="icon-info"></i>3. Klinische Angaben</h2>
                        <div className="text">
                            <h4>Anamnese</h4>
                            <p style={{overflowY:'auto', maxHeight:'150px'}}>{props.inquiry.analysis.anamnesis}</p>
                        </div>
                        {props.inquiry.analysis?.clinical_diagnostic &&
                            <div className="text">
                                <h4>Klinischer Befund</h4>
                                <p style={{overflowY:'auto', maxHeight:'150px'}}>{props.inquiry.analysis.clinical_diagnostic}</p>
                            </div>}
                        {props.inquiry.analysis?.question &&
                            <div className="text">
                                <h4>Fragestellung</h4>
                                <p style={{overflowY:'auto', maxHeight:'150px'}}>{props.inquiry.analysis.question}</p>
                            </div>}
                        <div className="d-flex flex-row align-items-center justify-content-end pt-4">
                            <button onClick={() => props.goto(3)} title="Bearbeiten" className="d-block border-0 link-primary text-end p-0 m-0">Bearbeiten</button>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                    <div className="bg-lightgrey font-black p-4 step04 h-100">
                        <h2 className="font-green pb-3"><i className="icon-upload"></i>4. Ihre Dateien</h2>
                        {props.inquiry.files?.uploads &&
                            (<div className="text mb-4" >
                                <h5>Uploads</h5>
                                <div className="list" style={{overflowY:'auto', maxHeight:'400px'}}>
                                {props.inquiry.files.uploads.map((upload, key) => {
                                    return <div key={key} className="d-block link-upload-cancel my-1 p-2"><i className="icon-pdf i-right"></i>{upload.basename}</div>
                                })}
                                </div>
                            </div>)}
                        {props.inquiry.files?.extended &&
                            (<div className="text" >
                                <h5>Bereits hochgeladen</h5>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <p className="mb-0">Übertragungsart</p>
                                        <strong>{translations[props.inquiry.files.extended.file_source]}</strong>
                                    </div>
                                    <div className="col-6">
                                        <p className="mb-0">Anzahl Bilder</p>
                                        <strong>{props.inquiry.files.extended.amount}</strong>
                                    </div>
                                    { props.inquiry.files.extended.vetsxl_link &&
                                        <div className="col-12 mt-3">
                                            <p className="mb-0">VETSXL Downloadlink</p>
                                            <strong>{props.inquiry.files.extended.vetsxl_link}</strong>
                                        </div>}
                                </div>
                            </div>)}

                        <div className="d-flex flex-row align-items-center justify-content-end pt-4">
                            <button onClick={() => props.goto(4)} title="Bearbeiten" className="d-block border-0 link-primary text-end p-0 m-0">Bearbeiten</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row text-right">
                <div className="col-12 text-center text-sm-end pt-3">
                    <button type="button" onClick={props.backward} className="py-1 px-3 rounded-pill btn-grey mb-3"><i className="icon-left-circle i-right"></i>Zurück</button>
                    {props.inquiry.active ?
                        <button onClick={props.finished} className="py-1 px-3 rounded-pill btn-green ms-3 mb-3">Fertig<i className="icon-right-circle i-right"></i></button>:
                        <>
                        <Link to="/anfragen" className="py-1 px-3 rounded-pill btn-grey mb-3 mx-3"><i className="icon-left-circle i-right"></i>Alle Anfragen</Link>
                        <button onClick={activate} disabled={activating} className="py-1 px-3 rounded-pill btn-green mb-3">Anfrage versenden<i className="icon-send i-left"></i></button>
                        </>
                    }
                </div>
            </div>
        </div>
}