import { useEffect, useRef, useState } from "react";
import getInquirySets from "../../../hooks/getInquirySets";
import saveRegions from "../../../hooks/saveRegions";
import { Loading } from "../../App/Loading";
import { UncontrolledTooltip } from "reactstrap";

export default function Regions(props) {
    const [responses, setResponse] = useState(null);
    const [sets, setSets] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selection, setSelection] = useState([]);
    const procedureSelect = useRef(null);
    const [contrastMedium, hasContrastMedium] = useState(false);
    const [currentProcedure, setCurrentProcedure] = useState('');
    const [currentRegion, setCurrentRegion] = useState('');
    const [currentPosition, setCurrentPosition] = useState('');

    useEffect(() => {
        if (loading && sets === null) {
            getInquirySets(2, props.id).then((response) => {
                if (response.status) {
                    setSets(response.sets);
                    let procedure = Object.keys(response.sets.procedures)[0];
                    setCurrentProcedure(procedure);
                    setCurrentRegion(Object.keys(response.sets.procedures[procedure].regions)[0]);
                    if (props.inquiry)
                        setSelection(props.inquiry);
                    setLoading(false);
                } else {
                    setResponse([{ type: 'error', text: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support" }]);
                }
            });
        }

        if(props.contrast_medium !== undefined)
            hasContrastMedium(props.contrast_medium);
    }, [loading, sets, props.inquiry]);


    let add = () => {
        if (!selection.filter(test => {
            return (test.procedure === currentProcedure && test.region === currentRegion && test.position === currentPosition)
        }).length) {
            setSelection(selection => [...selection,
            {
                procedure: currentProcedure,
                region: currentRegion,
                position: currentPosition
            }
            ]);
            procedureSelect.current.focus();
        }
    }

    let del = (selectedProcedure, selectedRegion, selectedPosition) => {
        setSelection(selection => selection.filter(test => {
                return (test.procedure !== selectedProcedure || test.region !== selectedRegion || test.position !== selectedPosition)
            })
        );
    }

    let onSubmit = (e) => {
        if (!selection) {
            setResponse([{ type: 'error', text: "Bitte mindestens einen Eintrag auswählen" }]);
        } else {
            if (selection && selection.length) {
                saveRegions(props.url, {'selection': selection, 'contrast_medium': contrastMedium }).then((response) => {
                    if (response.status) {
                        props.forward();
                    } else {
                        setResponse(response.error);
                    }
                });
            } else {
                setResponse([{ type: 'error', text: "Bitte mindestens einen Eintrag auswählen" }]);
            }
        }
        e.preventDefault();
    }

    let selectCurrentProcedure = (procedure) => {
        setCurrentProcedure(procedure);
        let region = Object.keys(sets.procedures[procedure].regions)[0];
        setCurrentRegion(region);
        setCurrentPosition(sets.procedures[procedure].regions[region].length ? sets.procedures[procedure].regions[region][0] : null);
    }

    let selectCurrentRegion = (region) => {
        setCurrentRegion(region);
        setCurrentPosition(sets.procedures[currentProcedure].regions[region].length ? sets.procedures[currentProcedure].regions[region][0] : null);
    }


    return loading ?
        <Loading /> :
        <form onSubmit={onSubmit} >
            <div className="container bg-lightgrey mt-3 mb-0 p-4 px-4 px-sm-5">
                {responses &&
                    <div className="messages">
                        {responses.map((message, i) => {
                            return (<span key={i} className={message.type + " mb-2"}>{message.text}</span>)
                        })}
                    </div>
                }
                <div className="row justify-content-start step02 position-relative">
                    <h2 className="font-green"><i className="icon-bone"></i>2. Körperregion</h2>
                    <div id="form_check_contrast_medium" className="form-check">
                        <input className="form-check-input" type="checkbox" id="contrast_medium" name="contrast_medium" onChange={e => hasContrastMedium(e.target.checked)} checked={contrastMedium} />
                        <label className="form-check-label" id="label_contrast_medium" htmlFor="contrast_medium">
                            Mit Kontrastmittel
                            <UncontrolledTooltip placement={'top'} target={"label_contrast_medium"}>
                                Wurde Kontrastmittel für die Aufnahme der Bilder verwendet? 
                            </UncontrolledTooltip>
                        </label>
                    </div>
                    <div className="input-group my-4">
                        <select className="form-select" autoFocus={true} ref={procedureSelect}
                            onChange={e => selectCurrentProcedure(e.target.value)} value={currentProcedure}>
                            {
                                Object.keys(sets.procedures).map((procedure) => {
                                    return <option key={procedure} value={procedure}>{sets.translations.procedures[procedure]}</option>
                                })
                            }
                        </select>
                        {currentProcedure &&
                            <select className="form-select" onChange={e => selectCurrentRegion(e.target.value)} value={currentRegion}>
                                {Object.keys(sets.procedures[currentProcedure].regions).map((region) => {
                                    return <option key={region} value={region}>{sets.translations.regions[region]}</option>
                                })}
                            </select>}
                        {currentRegion && sets.procedures[currentProcedure].regions[currentRegion].length > 0 &&
                            <select className="form-select" onChange={e => setCurrentPosition(e.target.value)} value={currentPosition}>
                                <option value="LEFT">Links</option>
                                <option value="RIGHT">Rechts</option>
                            </select>}
                        <button type="button" onClick={add} className="btn btn-green" >Hinzufügen</button>
                    </div>
                </div>
                <div className="filter-options mt-4">
                    {selection && selection.map((s, key) => {
                        return <button key={s.procedure + '-' + s.region + '-' + s.position}
                            onClick={() => del(s.procedure, s.region, s.position)}
                            className="mb-3 py-1 px-3 me-sm-3 rounded-pill btn-green user-select-none pe-auto"
                        >
                            {sets.translations.procedures[s.procedure]} | {sets.translations.regions[s.region]} {s.position ? "| " + sets.translations.positions[s.position] : ''}
                            <i className="icon-cancel-circled i-left"></i>
                        </button>
                    })}
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center text-sm-end pt-3">
                    <button type="button" onClick={props.backward} className="py-1 px-3 rounded-pill btn-grey mb-3 me-2"><i className="icon-left-circle i-right"></i>Zurück</button>
                    <button type="submit" className="py-1 px-3 rounded-pill btn-green mb-3">Speichern</button>
                </div>
            </div>
        </form>
}