import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import getUsers from "../../hooks/getUsers";
import { Toast, ToastBody, ToastHeader, UncontrolledTooltip } from "reactstrap";
import {deleteUser as delUser} from "../../hooks/deleteUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar, faPencil, faStar, faTimes, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

export default function Table(){
    const [message, setMessage] = useState({});
    const [tooltipOpen, isTooltipOpen] = useState(true);
    const queryClient = useQueryClient();
    const { state } = useLocation();

    const { isLoading, error, data}  = useQuery({ queryKey: ['users'], queryFn: async() => await getUsers()})

    // delete a draft or inquiry
    const deleteUser = useMutation({mutationFn: (id) => {
            return delUser(id);
        },
        onSuccess: (data,id) => {
            setMessage({isOpen: true ,title:'Benutzeraccount ID '+id+' gelöscht', description: 'Benutzeraccount ID.'+id+' wurde erfolgreich gelöscht'});
            queryClient.invalidateQueries({ queryKey: ['users'] });
            setTimeout(() => {
                setMessage({...message, isOpen:false });
            }, 4000);
        }
    });

    useEffect(() => {
        if(state?.message){
            setTimeout(() => isTooltipOpen(false),3000);
        }
    },[state]);


    //  loading all requests failed
    if(error)
        return <p>Leider gab es einen Fehler beim Laden der Benutzer. Bitte wenden Sie sich an den Support.</p>

    // is loading
    if(isLoading)
        return <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col" className="table-title">Name</th>
                            <th scope="col" className="table-title">Vorname</th>
                            <th scope="col" className="table-title">E-Mail Adresse</th>
                            <th scope="col" className="table-title">Telefonnummer</th>
                            <th scope="col" className="table-title">Position</th>
                            <th scope="col" className="table-title text-center">Status</th>
                            <th scope="col" className="table-title text-end">Optionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                        </tr>
                        <tr>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                        </tr>
                        <tr>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                            <td><div className="placeholder-loading"></div></td>
                        </tr>
                    </tbody>
                </table>;

    // overview
    return <>
        {state?.message &&
        <Toast isOpen={tooltipOpen ? true : false} className='position-absolute' style={{top: '10px', left: "calc(50% - 175px)"}}>
            <ToastHeader icon={<i className={"icon"+(state.message.type === 'success' ? 
                                                        "icon-check text-success" : 
                                                        "icon-cancel text-danger")}>
                                </i>}>
                {state.message.title}
            </ToastHeader>
            <ToastBody>
                {state.message.description}
            </ToastBody>
        </Toast>}
        <Toast isOpen={message && message.isOpen ? true : false} className='position-absolute' style={{top: '10px', left: "calc(50% - 175px)"}}>
            <ToastHeader icon={<i className="icon icon-check text-success"></i>}>
                {message?.title}
            </ToastHeader>
            <ToastBody>
                {message?.description}
            </ToastBody>
        </Toast>
        <div className="col-12 p-0 table-responsive pt-3">
            <div className="d-flex flex-row align-items-end justify-content-between">
                {data.length < 15 &&
                <Link to="/benutzer/neu" title="Neuen Benutzer hinzufügen" className="btn btn-outline-primary mb-3 py-1 px-3 me-sm-3 ">
                    <FontAwesomeIcon icon={faPlus} className='me-2'/>
                    Neuer Benutzer
                </Link>}
                <p className="text-muted ms-auto mb-0">{data.length}/15</p>
            </div>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col" className="table-title">Name</th>
                        <th scope="col" className="table-title">Vorname</th>
                        <th scope="col" className="table-title">E-Mail Adresse</th>
                        <th scope="col" className="table-title">Telefonnummer</th>
                        <th scope="col" className="table-title">Position</th>
                        <th scope="col" className="table-title text-center">Status</th>
                        <th scope="col" className="table-title text-end">Optionen</th>
                    </tr>
                </thead>
                <tbody>
                {data.map((user) => {
                        return <tr key={user.id}>
                                <td>{user.firstname}</td>
                                <td>{user.lastname}</td>
                                <td><a href={"mailto:"+user.email} title="anschreiben">{user.email}</a></td>
                                <td>
                                    {user.phone ?
                                    <a href={"tel:"+user.phone} title="anrufen">{user.phone}</a> :
                                    '-'}
                                </td>
                                <td>{user.company_position_label}</td>
                                <td className="text-center">
                                    {user.role === 'PRIMARY' ? 
                                        <>
                                            <FontAwesomeIcon icon={faStar} id={"user-"+user.id+"-star"} className="text-warning fs-4 mx-2"/>
                                            <UncontrolledTooltip placement={'top'} target={"user-"+user.id+"-star"}>
                                            Hauptkontakt
                                            </UncontrolledTooltip>
                                        </>: 
                                    '' } 
                                    {user.active === 'FALSE' ? 
                                        <>
                                            <FontAwesomeIcon icon={faTimes} id={"user-"+user.id+"-times"} className="text-danger fs-3 mx-2" />
                                            <UncontrolledTooltip placement={'top'} target={"user-"+user.id+"-times"}>
                                            Inaktiv
                                            </UncontrolledTooltip>
                                        </>: 
                                    ''}
                                    {user.company_position === 'ACCOUNTING' ? 
                                        <>
                                            <FontAwesomeIcon icon={faFileInvoiceDollar} id={"user-"+user.id+"-accounting"} className="text-secondary fs-3 mx-2" />
                                            <UncontrolledTooltip placement={'top'} target={"user-"+user.id+"-accounting"}>
                                            Buchhaltung - Rechnungen
                                            </UncontrolledTooltip>
                                        </>: 
                                    ''}
                                </td>
                                <td className="text-end">
                                    <Link to={"/benutzer/"+user.id} className="px-2 table-link">
                                        <FontAwesomeIcon icon={faPencil} id={"user-"+user.id+"-pencil"} className="fs-5"/>
                                        <UncontrolledTooltip placement={'top'} target={"user-"+user.id+"-pencil"}>
                                        Bearbeiten
                                        </UncontrolledTooltip>
                                    </Link>
                                    {user.canDelete &&
                                    <button className="btn table-link" onClick={() => deleteUser.mutate(user.id)}>
                                        <FontAwesomeIcon icon={faTrashAlt} id={"user-"+user.id+"-trash"} className="fs-5"/>
                                        <UncontrolledTooltip placement={'top'} target={"user-"+user.id+"-trash"}>
                                        Löschen
                                        </UncontrolledTooltip>
                                    </button>}
                                </td>
                            </tr>
                    })}
                </tbody>
            </table>
        </div>
    </>
}