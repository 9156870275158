import axios from "axios";

export async function deleteUser(id){
    return await axios.delete('/me/users/'+id).then((response) => {
        if(response.status === 204)
            return {status: true};

        return {status: false, error: [{type: 'error', text: response.message}]};
    }).catch((error) => {
        return {status: false, error: [{type: 'error', text: error.response.data.message}]};
    });
}