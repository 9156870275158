import axios from "axios";

export default async function activateInquiry(link) {
    return await axios.put(link+'/activate').then((response) => {
        if(response.status === 200) 
            return true;

        return false;
    }).catch(() => {
        return false;
    });
}