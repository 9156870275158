import axios from "axios";

export default async function getUserSets(){
    return await axios.get('/me/users/sets').then((response) => {
        if(response.status === 200)
            return {status:true, sets: response.data};

        return {status:false};
    }).catch(() => {
        return {status:false};
    });
}