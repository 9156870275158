import axios from "axios";
import { Navigate } from "react-router-dom";
import { Loading } from "../App/Loading";
import { useAuth } from "./AuthProvider"

export const ProtectedRoute = (props) => {
    const auth = useAuth();

    axios.interceptors.response.use((response) => { return response; },(error) => {
        switch (error.response.status) {
            case 419: // Session expired
            case 401: // Not logged in
                if (auth.logged === true)
                    auth.logout();
                break;
            default:
                // Allow individual requests to handle other errors
                return Promise.reject(error);
        }
        return Promise.reject(error);
    });

    if(auth.logged !== null && props?.restricted){
        // Has no right & routes role is empty or has no correct role
        if(!auth.hasRight() && 
            ((props.role === undefined || props.role === null || props.role.length <= 0) ||
            !auth.hasRole(props.role)))
            return <Navigate to="/dashboard" />
        
    } 

    return auth.logged === null ?
        <Loading /> :
        (auth.logged === true ?
            (props.guest === true ?
                <Navigate to="/dashboard" /> :
                props.children) :
            (props.guest === true ?
                props.children :
                <Navigate to="/startseite" />)
        );
}