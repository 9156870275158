import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { getPasswordResetLink } from '../../hooks/getPasswordResetLink';

export const PasswordReset = () => {
    const [requested, setRequested] = useState(false);
    const [responses, setResponse] = useState(null);

    return requested ? 
    <div className='container'>
        <h2>Angefordert</h2>
        <p>Password-Reset Link wurde versendet</p>
    </div> :
    <Formik
        initialValues={{ email: '' }}
        validate={values => {
            const errors = {};
            if (!values.email) {
                errors.email = 'Pflichtfeld';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Ungültige E-Mail-Addresse';
            }

            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            getPasswordResetLink(values.email).then((response) => {
                if(response === true) {
                    setRequested(true);
                    setSubmitting(true);
                }else{
                    setRequested(false);
                    setSubmitting(false);
                    setResponse([response]);
                }
            });
        }}
    >
        {(props) => (
            <Form>
                {responses && 
                    <div className='messages'>
                        {responses.map((message, i) => {
                            return (<span key={i} className='error mb-2'>{message}</span>) 
                        })}
                    </div>}
                <div className='row align-items-center pt-3 my-3'>
                    <div className="col-6">
                        <Field type="email" name="email" placeholder="E-Mail-Adresse" className="form-control" />
                    </div>
                    <div className='col-12 mt-3'>
                        <button type="submit" disabled={props.isSubmitting} className="py-1 px-3 rounded-pill btn-green mb-3">Link anfordern</button>
                    </div>
                </div>
            </Form>
        )}
    </Formik>;
}