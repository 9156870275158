import { useNavigate } from "react-router-dom";
import Status from "./Status";
import Infos from "./Steps/Infos";

export function CreateForm(){
    const navigate = useNavigate();

    return <>
        <h2 className="mb-5">Neue Anfrage</h2>
        <Status step={1} />
        <Infos edit={false} baseUrl="/me/inquiries" cancel={() => {navigate('/anfragen');}}/>
    </>
}