import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import getHistory from "../../hooks/getHistory";
import { Loading } from "../App/Loading";
import Teaser from "./Teaser"
import Message from './Message';

export default function Inbox({id, baseUrl, updateTimestamp}){
    const [selectedMessage, selectMessage] = useState(null);
    const [currentId, setCurrentId] = useState(null);

    const { isLoading, data }  = useQuery({ 
        queryKey: ['communication-history', {baseUrl, id, updateTimestamp}], 
        queryFn: async() => await getHistory(baseUrl),
        refetchInterval: 20000,
        enabled: !!baseUrl,
    });

    useEffect(() => {
        if(!isLoading &&  (!selectedMessage || id != currentId)){
            selectMessage(data.history[0].id);
            setCurrentId(id);
        }
    }, [selectedMessage,isLoading, id]);


    return <div className="row align-items-start p-4 bg-lightgrey font-black communication">
        <div id="history" className="col-8 col-md-5 col-xl-4">
            <div className="nav flex-column bg-white nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                {isLoading  ?
                <Loading />:
                data.history.map((entry) => {
                    return <Teaser key={entry.id}
                        entry={entry}
                        selectMessage={selectMessage}
                        active={selectedMessage && entry.id === selectedMessage}
                        url={baseUrl} />
                })}
            </div>
        </div>
        <div id="message" className="col-12 col-md-7 col-xl-8">
            {isLoading ?
                <Loading />:
                id == currentId && <Message url={baseUrl} id={selectedMessage} />}
        </div>
    </div>
}