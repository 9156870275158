import { Navigate } from "react-router-dom";
import Layout from "../components/App/Layout";
import Dashboard from "../routes/dashboard";
import DataPrivacy from "../routes/data-privacy";
import FAQ from "../routes/faq";
import Home from "../routes/home";
import LegalNotice from "../routes/legal-notice";
import PasswordChange from "../routes/password-change";
import PasswordReset from "../routes/password-reset";
import Registration from "../routes/registration";
import Requests from "../routes/requests";
import TermsOfService from "../routes/terms-of-service";
import Invoices from "../routes/invoices";
import Pricelist from "../routes/pricelist";
import Users from "../routes/users";
import ErrorPage from "../routes/error";
import NotFoundPage from "../routes/404";

import UserForm from '../components/Users/Form';
import UserTable from '../components/Users/Table';
import {CreateForm as InquiryCreateForm} from '../components/Inquiry/CreateForm';
import {UpdateForm as InquiryUpdateForm} from '../components/Inquiry/UpdateForm';
import {Error as ErrorRequestPage} from '../components/Inquiry/Error';
import RequestTable from '../components/Requests/Table';
import InvoiceTable from '../components/Invoice/Table';
import Communication from '../components/Messenger/Client';
import { AuthProvider } from "../components/Auth/AuthProvider";
import { ProtectedRoute } from "../components/Auth/ProtectedRoute";
import getRegistrationSets from "./getRegistrationSets";
import Profile from "../routes/profile";

export default function createRoutes(){
    return [{
        element:<AuthProvider><Layout /></AuthProvider>,
        children: [
            {
                path:'',
                element: <Navigate replace to="startseite" />
            },
            {
                path:'startseite',
                element: <Home />
            },
            {
                path:'registrierung',
                loader: async () => {
                    return getRegistrationSets().then((response) => {
                        if(response.status)
                            return response;

                        throw Error(' Registration can not work without sets');
                    });
                },
                element: <ProtectedRoute guest={true} ><Registration /></ProtectedRoute>
            },
            {
                path:'passwort-vergessen',
                element: <ProtectedRoute guest={true} ><PasswordReset /></ProtectedRoute>
            },
            {
                path:'passwort-aendern',
                element: <ProtectedRoute guest={true} ><PasswordChange /></ProtectedRoute>
            },
            {
                path:'faq',
                element: <FAQ />
            },
            {
                path:'agb',
                element: <TermsOfService />
            },
            {
                path:'impressum',
                element: <LegalNotice />
            },
            {
                path:'datenschutz',
                element: <DataPrivacy />
            },
            {
                path:'dashboard',
                element: <ProtectedRoute><Dashboard /></ProtectedRoute>
            },
            {
                path:'anfragen',
                element: <ProtectedRoute><Requests /></ProtectedRoute>,
                errorElement: <ErrorRequestPage />,
                children:[
                    {
                        path: '',
                        element: <RequestTable />
                    },
                    {
                        path: ':id',
                        element: <InquiryUpdateForm />
                    },
                    {
                        path: 'neu',
                        element: <InquiryCreateForm />
                    },
                    {
                        path: ':id/kommunikation',
                        element: <Communication isInquiry={true} />
                    },
                    {
                        path:'auftrag/:id/kommunikation',
                        element: <Communication isInquiry={false} />
                    }
                ]
            },
            {
                path:'rechnungen',
                element: <ProtectedRoute restricted={true} role="ACCOUNTING"><Invoices /></ProtectedRoute>,
                children:[
                    {
                        path: '',
                        element: <InvoiceTable />
                    }
                ]
            },
            {
                path:'preisliste',
                element:<ProtectedRoute><Pricelist /></ProtectedRoute>,
            },
            {
                path:'benutzer',
                element:<ProtectedRoute restricted={true}><Users /></ProtectedRoute>,
                children:[
                    {
                        path: '',
                        element: <UserTable />
                    },
                    {
                        path: ':userId',
                        element: <UserForm mode={'edit'} />
                    },
                    {
                        path: 'neu',
                        element: <UserForm mode={'new'} />
                    }
                ]
            },
            {
                path: 'profil',
                element: <ProtectedRoute><Profile /></ProtectedRoute>
            },
            {
                path:'*',
                element:<NotFoundPage />,
            },
        ],
        errorElement: <ErrorPage />
    }];
}