import Upload from "./Upload";
import { useEffect, useState } from "react";
import { Loading } from "../../App/Loading";import getInquirySets from "../../../hooks/getInquirySets";
import saveFiles from "../../../hooks/saveFiles";
import { ErrorMessage, Field, Form, Formik } from "formik";

export default function Files(props) {
    const [responses, setResponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [fileSources, setFileSources] = useState(null);
    const [uploading, isUploading] = useState(false);
    const [uploads, hasUploads] = useState(false);

    const goBackwards = props.backward;
    const url = props.url;

    useEffect(() => {
        if(loading && fileSources === null) {
            getInquirySets(4).then((response) => {
                if(response.status) {
                    setFileSources(response.sets.file_sources);
                    setLoading(false);
                }
            });
        }
    },[loading, fileSources, props.url]);

    return loading ?
        <Loading />  :
        <Formik 
            initialValues={{
                file_source: props?.inquiry?.extended?.file_source ?? '',
                amount: props?.inquiry?.extended?.amount ?? '',
                vetsxl_link: props?.inquiry?.extended?.vetsxl_link ?? ''
            }}
            
            validate={(values,isSubmitting) => {
                const errors = {};

                if(!values.file_source &&  values.amount){
                    errors.file_source = 'Bitte eine Übertragungsart auswählen';
                }else if(values.file_source &&  !values.amount){
                    errors.amount = 'Bitte Anzahl angeben';
                }else if(values.file_source && !Object.keys(fileSources).includes(values.file_source)){
                    errors.file_source = 'Bitte eine gültige Übertragungsart auswählen';
                }
                return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {
                if(!values.file_source && !values.amount){
                    if(uploads){
                        saveFiles(url);
                        setSubmitting(true);
                        props.forward();
                    }else{
                        setSubmitting(false);
                        setResponse([{type: 'error', text: 'Bitte eins von beiden angeben: Entweder Datein zum hochladen oder Dateien die bereits hochgeladen sind'}]);
                    }
                }else{
                    saveFiles(url, {
                        extended: {
                            amount: values.amount,
                            file_source: values.file_source,
                            vetsxl_link: values.vetsxl_link,
                        }
                    }).then((response) => {
                        if(response.status){
                            setSubmitting(true);
                            props.forward();
                        }else{
                            setSubmitting(false);
                            setResponse(response.error);
                        }
                    });
                }
            }}>
            {(props) => (<Form className="container step04">
                <div className="bg-lightgrey mb-0 p-4 px-4 px-sm-5 mt-3">
                    <div className="row justify-content-center">
                        <h2 className="font-green"><i className="icon-upload"></i>4. Ihre Dateien</h2>
                        {responses && 
                            <div className="messages">
                                {responses.map((message, i) => {
                                    return (<span key={i} className={message.type+" mb-2"}>{message.text}</span>) 
                                })}
                            </div>
                        }
                    </div>
                    <div className="row">
                        <div className="mb-3  col-12 col-lg-6 pr-5 pt-3 font-black">
                            <Upload url={url}
                                    hasUploads={hasUploads}
                                    isUploading={isUploading}/>
                        </div>
                        <div className="mb-3 col-12 col-lg-6 pr-5 pt-3 font-black">
                            <h4>Sie haben bereits etwas hochgeladen?</h4>
                            <p>Bitte geben Sie an, ob Sie die Bilder über den Server gesendet haben, oder kopieren Sie im Fall eines Versandes aus der Praxissoftware Easyvet den Weblink hier hinein. So garantieren Sie eine schnellstmögliche Bearbeitung. Bitte beachten Sie ein Versand über andere Portale ist nicht möglich.</p>
                            <label htmlFor="already-uploaded" className="form-label">Weitere Dateien</label>
                            <Field component="select" className="form-select" id="file_source" name="file_source">
                                <option value=''>Bitte auswählen</option>
                                { Object.entries(fileSources).map((val) => {
                                    return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                })}
                            </Field>
                            <ErrorMessage name="file_source">{msg => <span className='error'>{msg}</span>}</ErrorMessage>

                            <label htmlFor="amount" className="form-label mt-3">Anzahl gesendeter Serien und Bildanzahl</label>
                            <Field  id="amount" name="amount" className="form-control" />
                            <ErrorMessage name="amount">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                            { props.values.file_source && props.values.file_source === 'VETSXL' &&
                                <>
                                <label htmlFor="vetsxl_link" className="form-label mt-3">VETSXL Downloadlink</label>
                                <Field type="url" id="vetsxl_link" name="vetsxl_link" className="form-control" />
                                <ErrorMessage name="vetsxl_link">{msg => <span className="error">{msg}</span>}</ErrorMessage>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center text-sm-end pt-3 ">
                        <button type="button" onClick={goBackwards} disabled={props.isSubmitting || uploading} className="py-1 px-3 me-2 rounded-pill btn-grey mb-3"><i className="icon-left-circle i-right"></i>Zurück</button>
                        <button type="submit" className="py-1 px-3 rounded-pill btn-green mb-3" disabled={props.isSubmitting || uploading}>Speichern</button>
                    </div>
                </div>
            </Form>)}
        </Formik>
}