import axios from "axios";

export default async function saveUser(mode, id, user){
    return await axios({
        method: mode === 'edit' ? 'put' : 'post',
        url: mode === 'edit' ? '/me/users/'+id : '/me/users',
        data: {
            title: user.title,
            address: user.address,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            company_position: user.company_position
        }
    }).then((response) => {
        if(response.status === 201)
            return {status: true, mode:'created', id: response.data.id};
            
        if(response.status === 204)
            return {status: true, mode:'updated'};
        
        return {status: false, error: [{ type: 'error', text: response.message }]}
    }).catch(function (error) {
        if(error.code === 'ERR_NETWORK')
            return {status: false, error: [{ type: 'error', text: 'Netzwerk Probleme' }]}
            
        if(error.response.status === 401)
            return {status: false, error: [{ type: 'error', text: error.response.data.error }]}

        if(error.response.status === 422){
                let errors = error.response.data.errors;
                let messages = [];
                for (var key in errors) {
                    for (var message of errors[key]) {
                        messages.push({ type: 'error', text: message });
                    }
                }
            return {status: false, error: messages};
        }

        return {status: false, error: [{ type: 'error', text: "Fehler ist aufgetreten. Bitte kontaktieren Sie den Support" }]};
    });
}