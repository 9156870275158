import axios from "axios";

export default async function getRegistrationSets() {
    return await axios.get('/registration/sets')
        .then((response) => {
            if(response.status === 200)
                return {
                    status: true,
                    countries: response.data.countries,
                    salutations: response.data.salutations,
                    types: response.data.types,
                    company_positions: response.data.company_positions,
                };

            return { status: false };
        }).catch(() => {
            return { status: false };
        });
}