import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useParams } from "react-router-dom";
import getCommunication from "../../hooks/getCommunication";
import { Loading } from "../App/Loading";
import Editor from "./Editor";
import Inbox from './Inbox';

export default function Client({isInquiry}){
    const [updateOnSent, setUpdateOnSent] = useState('current');
    let param = useParams();
    const baseUrl = 'me/'+(isInquiry ? 'inquiries' : 'orders')+'/'+param.id+'/communication';
    
    const { isLoading, error, data, refetch }  = useQuery({ 
        queryKey: ['communication', param.id], 
        queryFn: async() => await getCommunication(baseUrl)
    });

    if(isLoading)
        return <Loading />

    const messageSent = (timestmap) =>{
        if(!data?.data?.communication){
            refetch();
        }else{
            setUpdateOnSent(timestmap);
        }
    }

    return <><Editor url={baseUrl} sent={messageSent} entry={isInquiry ? data.data.inquiry : data.data.order} isInquiry={isInquiry}/>
    {data.data?.communication && 
    <div className="container my-5">
        <h2 className="font-green">Kommunikationsverlauf</h2>
        <Inbox id={param.id} baseUrl={baseUrl} updateTimestamp={updateOnSent}/>
    </div>
    }</>
}