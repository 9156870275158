import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faPencil, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

export default function RowInquiry({inquiry, deleteRequest}){
    var date_options = { year: 'numeric', month: '2-digit', day: '2-digit'};
    var time_options = { hour: '2-digit', minute: '2-digit'};

    var created_at = new Date(inquiry.created_at);
    var updated_at = new Date(inquiry.updated_at);

    return <tr>
        <td>
            <span className="me-2">{inquiry.id}</span>
            {inquiry.importance  && 
            <>
            <FontAwesomeIcon icon={faFlag} id={"inquiry-"+inquiry.id+"-importance"} className="text-danger fs-6" />
            <UncontrolledTooltip placement={'top'} target={"inquiry-"+inquiry.id+"-importance"}>
            Dringlicher Anfrage
            </UncontrolledTooltip>
            </>
            }
        </td>
        <td>
            <span>{inquiry.pet_owner}</span>
        </td>
        <td>
            <span>{inquiry.pet_name}</span>
        </td>
        <td>
            <span>{created_at.toLocaleDateString('de-DE',date_options)}</span>
        </td>
        <td>
            <span className="me-2">{updated_at.toLocaleDateString('de-DE',date_options)}</span>
            <span className="text-muted small">{updated_at.toLocaleTimeString('de-DE',time_options)}</span>
        </td>
        <td><span className="badge bg-open d-block">Offen</span></td>
        <td className="text-center">-</td>
        <td className="text-end">
            <Link id={"inquiry-"+inquiry.id+"-edit"} to={"/anfragen/"+inquiry.id} className="px-2 table-link">
                <FontAwesomeIcon icon={faPencil} className="fs-5" />
                <UncontrolledTooltip placement={'top'} target={"inquiry-"+inquiry.id+"-edit"}>
                Bearbeiten
                </UncontrolledTooltip>
            </Link>
            <button id={"inquiry-"+inquiry.id+"-delete"} onClick={() => deleteRequest.mutate(inquiry.id,true,false)} className="btn px-2 py-0 table-link">
                <FontAwesomeIcon icon={faTrashAlt} className="fs-5" />
                <UncontrolledTooltip placement={'top'} target={"inquiry-"+inquiry.id+"-delete"}>
                Löschen
                </UncontrolledTooltip>
            </button>
            <Link id={"inquiry-"+inquiry.id+"-messages"} to={"/anfragen/"+inquiry.id+"/kommunikation"} className="px-2 table-link">
                <i className="icon-new-chat text-primary fs-5"></i>
                <UncontrolledTooltip placement={'top'} target={"inquiry-"+inquiry.id+"-messages"}>
                Kommunikation
                </UncontrolledTooltip>
            </Link>
        </td>
    </tr>    
}