import { Link } from "react-router-dom";
import { useAuth } from "../components/Auth/AuthProvider";
import katzenscellet from "./../assets/images/Katzenskelett_transparent.png"

export default function Pricelist() {
    const auth = useAuth();
    return (
        <div className="inside">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Preisliste</h1>
                                    <p>Preise und Fristen für CT-, MRT- und Röntgen-Befundungen</p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenscellet} alt="Katzen-Röntgen" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12 col-md-10 col-xl-8 offset-md-1 offset-xl-2 font-black">
                        <h2>Preise und Fristen für CT-, MRT- und Röntgen-Befundungen</h2>
                        <p>für die folgenden Körperregionen:</p>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td><strong>CT</strong></td>
                                    <td><p style={{wordBreak:'break-word', whiteSpace: 'normal'}}>Kopf, Hals, Thorax, Abdomen, Vordergliedmaße, Hintergliedmaße, Halswirbelsäule,
                                     Brust- und Lendenwirbelsäule, Becken</p></td>
                                </tr>
                                <tr>
                                    <td><strong>MRT</strong></td>
                                    <td><p style={{wordBreak:'break-word', whiteSpace: 'normal'}}>Kopf, Schulter-, Ellbogen-, Knie-, Karpal-, oder Tarsalgelenk mit Pfote,
                                    Plexus brachialis, Becken, Halswirbelsäule, Brust- und Lendenwirbelsäule</p></td>
                                </tr>
                                <tr>
                                    <td><strong>Röntgen</strong></td>
                                    <td><p style={{wordBreak:'break-word', whiteSpace: 'normal'}}>Kopf, Hals, Thorax, Abdomen, Vorder- oder Hintergliedmaße (1 Region = 1 Gelenk im Seitenvergleich;
                                     2 Gelenke der gleichen Gliedmaße), Halswirbelsäule, Brust- und Lendenwirbelsäule</p></td>
                                </tr>
                            </tbody>
                        </table>
                        <ol>
                            <li><p>Bilder für Befunde, die während der <strong>regulären Geschäftszeiten (= Montag bis Freitag 09:00 bis 17:00 Uhr, deutsche Feiertage ausgenommen)</strong> beauftragt werden, werden innerhalb von 24 Stunden nach Erhalt aller erforderlichen Unterlagen ausgewertet.
Für Befunde, die außerhalb der regulären Geschäftszeiten beauftragt werden, beginnt die 24-Stunden-Frist am nächsten Werktag um 9:00 Uhr.</p></li>
                            <li>
                                <p>Folgende Preise fallen an für die jeweilige Befundung:</p>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}><strong>CT- und MRT-Befundungen</strong></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><strong>Hund, Katze</strong></td>
                                            <td colSpan={2}><strong>Heimtiere, Vögel</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Befund 1.Körperregion</td>
                                            <td>100,00 €*</td>
                                            <td>Befund 1.Körperregion</td>
                                            <td>100,00 €*</td>
                                        </tr>
                                        <tr>
                                            <td>jede weitere Körperregion</td>
                                            <td>50,00 €*</td>
                                            <td>Befund 2 Körperregionen</td>
                                            <td>150,00 €*</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td>Ganzkörper</td>
                                            <td>200,00 €*</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}><strong>Röntgen-Befundungen</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Befund 1.Körperregion Grundpreis</td>
                                            <td>34,50 €*</td>
                                            <td>jede weitere Körperregion</td>
                                            <td>17,25 €*</td>
                                        </tr>
                                        <tr>
                                            <td>Zzgl. Mehraufwand bei</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Kontrastmittelpassage MDT</td>
                                            <td></td>
                                            <td>34,50 €*</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>sonstige Kontrastmittelstudien</td>
                                            <td></td>
                                            <td>34,50 €*</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Stressröntgenaufnahmen pro Gelenk</td>
                                            <td></td>
                                            <td>17,25 €*</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td colSpan={4}><strong>Ultraschall-Befundungen</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Befund 1.Körperregion Grundpreis</td>
                                            <td>33,00 €*</td>
                                            <td>jede weitere Körperregion</td>
                                            <td>16,50 €*</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                            <li><p>Im Falle einer besonderen Dringlichkeit der Befundung fällt eine Notfallgebühr gemäß nachstehender Tabelle an:</p>
                            <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td>CT- und MRT-Befundungen</td>
                                            <td>50,00 €*</td>
                                        </tr>
                                        <tr>
                                            <td>Röntgen- und Ultraschall-Befundungen</td>
                                            <td>17,25 €*</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>In solchen Notfällen erfolgt die Befundung</p><br/>
                                <ol style={{ listStyleType: 'lower-alpha'}}>
                                    <li>bei Beauftragung während der regulären Geschäftszeit innerhalb von 6 Stunden ab Erhalt aller erforderlichen Unterlagen;</li>
                                    <li>bei Beauftragung außerhalb der regulären Geschäftszeit innerhalb von 18 Stunden ab Erhalt aller erforderlichen Unterlagen.</li>
                                </ol>
                                <p>Die Auftragnehmerin gibt keine Gewähr für die Möglichkeit der Notfallbefundung. Ist aus betrieblichen Gründen eine Notfallbefundung nicht möglich, wird keine Notfallgebühr berechnet.</p>
                            </li>
                        </ol>
                        <p>*<i>alle Preise verstehen sich zzgl. der gesetzlichen Mehrwertsteuer</i></p>
                    </div>
                </div>
            </div>
            <div className="container-fluid my-4 overflow-hidden">
                <div className="row px-2 px-sm-4 px-lg-0">
                    <div className="col-12 col-md-10 offset-md-1 offset-lg-0 col-lg-6 px-0">
                        <div className="bg-grey-left px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-10 pt-4 pb-4 col-xxl-9 offset-lg-2 offset-xxl-3 produktreihe pt-4 ps-3 ps-lg-0 pe-3 pe-lg-5 pb-0">
                                    <h3>Neue Anfrage erstellen</h3>
                                    <p>Hier geht es zum Antragsformular. </p>
                                    <Link to="/anfragen/neu" title="Zum Anfrageformular" className="white-rounded-link py-1 px-3 rounded-pill">zum Anfrageformular</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 offset-lg-0 col-lg-6 px-0 mt-4 mt-lg-0">
                        <div className="bg-grey-right px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 pt-4 pb-4 col-lg-10 col-xxl-9 produktreihe pt-4 pe-3 pe-lg-0 ps-3 ps-lg-5 pb-0">
                                {auth.hasRight() || auth.hasRole('ACCOUNTING') ?
                                <>
                                    <h3>Rechnungen</h3>
                                    <p>Hier finden Sie eine Übersicht über alle Ihre Rechnungen.</p>
                                    <Link to="/rechnungen" title="Zu den Rechnungen" className="white-rounded-link py-1 px-3 rounded-pill">zu den Rechnungen</Link>
                                </>:
                                <>
                                <h3>Fragen und Antworten</h3>
                                <p>Sie haben Fragen zu unserem Service? Hier finden Sie Antworten zu verschiedenen Fragen.
Ihre Frage ist nicht dabei, dann kontaktieren Sie uns unter kontakt@vetradiologie.de</p>
                                <Link to="/faq" title="Zu den FAQ" className="white-rounded-link py-1 px-3 rounded-pill">zu den FAQ</Link>
                                </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}