import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faFlag} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import getOrder from "../../hooks/getOrder";

export default function Details({id}){
    const [activeTab, setActiveTab] = useState(1);
    const { isLoading, error, data }  = useQuery({ queryKey: ['order_details', id], queryFn: async() => await getOrder(id)})

    if(isLoading)
        return <p>Loading</p>;

    if(error)
        return <p>Shit</p>;
        
    var date_options = { year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit'};

    return <>
        <div className="d-flex flex-row flex-nowrap align-items-end p-3">
            <h2>Auftrag {data.ordernumber}</h2>
            <span className="text-muted ms-auto small">{new Date(data.created_at).toLocaleDateString('de-DE',date_options)}</span>
        </div>
        <Nav tabs>
            <NavItem>
                <NavLink role="button" className={(activeTab === 1 ? "active" : '')+' user-select-none'} onClick={()=> {setActiveTab(1)}} >
                    <p className="mb-0 fst-italic"><strong>Informationen</strong></p>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink role="button" className={(activeTab === 2 ? "active" : '')+' user-select-none'} onClick={()=> {setActiveTab(2)}} >
                    <p className="mb-0 fst-italic"><strong>Körperregion</strong></p>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink role="button" className={(activeTab === 3 ? "active" : '')+' user-select-none'} onClick={()=> {setActiveTab(3)}} >
                    <p className="mb-0 fst-italic"><strong>Klinische Angaben</strong></p>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink role="button" className={(activeTab === 4 ? "active" : '')+' user-select-none'} onClick={()=> {setActiveTab(4)}} >
                    <p className="mb-0 fst-italic"><strong>Ihre Dateien</strong></p>
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className="p-3">
                <div className="row">
                    <div className="col-sm-6 col-12">
                        <label>Besitzer</label>
                        <p>{data.pet_owner}</p>
                        <label>Tiername</label>
                        <p>{data.pet_name}</p>
                        <label>Geschlecht</label>
                        <p>{data.pet_gender}</p>
                        <label>Geburtsjahr</label>
                        <p>{data.pet_birthyear}</p>
                        <label>Tierart</label>
                        <p>{data.pet_species}</p>
                    </div>
                    <div className="col-sm-6 col-12">
                        <label>Tierrasse</label>
                        <p>{data.pet_breed ?? '-'}</p>
                        <div className="d-flex flex-row align-items-center mb-3">
                            <FontAwesomeIcon icon={data.pet_neutered ? faCheck : faTimes}  className={data.pet_neutered ? 'text-primary': 'text-danger'}/>
                            <label className="ms-2">Kastriert</label>
                        </div>
                        <label>Ihre Auftragsnummer/Kennung</label>
                        <p>{data.custom_id ?? '-'}</p>
                        <label>Zuständige/r Tierarzt/Tierärztin</label>
                        <p>{data.doctor ?? '-'}</p>
                        {data.importance === 'HIGH' &&
                        <div className="d-flex flex-row align-items-center mb-3">
                            <FontAwesomeIcon icon={faFlag}  className='text-danger'/>
                            <label className="ms-2">Besondere Dringlichkeit</label>
                        </div>}
                    </div>
                    <div className="col-12">
                        <label>Vetrauliche Angaben</label>
                        <p>{data.description ?? '-'}</p>
                    </div>
                </div>
            </TabPane>
            <TabPane tabId={2} className="p-3">
                <div className="d-flex flex-row justify-content-end align-items-center mb-3">
                    <FontAwesomeIcon icon={data.contrast_medium ? faCheck : faTimes}  className={data.contrast_medium ? 'text-primary': 'text-danger'}/>
                    <label className="ms-2">Mit Kontrastmittel</label>
                </div>
                <div className="d-flex flex-row align-items-center flex-wrap">
                    {data.regions.map((selection, i) => {
                        return <p key={i} className="selection bg-primary mx-2 rounded-pill px-2 text-white fw-semibold small" >
                            <span>{selection.procedure}</span>
                            <span className="mx-2">|</span>
                            <span>{selection.region}</span>
                            {selection.position &&
                            <><span className="mx-2">|</span>
                            <span>{selection.position}</span></>}
                        </p>
                    })}
                </div>
            </TabPane>
            <TabPane tabId={3} className="p-3">
                <label>Anamnese</label>
                <div className="mb-3">
                    {data.anamnesis}
                </div>
                <label>Klinische Befunde</label>
                <div className="mb-3">
                    {data.clinical_diagnostic ?? '-'}
                </div>
                <label>Fragestellung</label>
                <div className="mb-3">
                    {data.question ?? '-'}
                </div>
            </TabPane>
            <TabPane tabId={4} className="p-3">
                {data.uploads && data.uploads.length > 0 && 
                <>
                    <h3 className="small">Dateiuploads</h3>
                    <ul className="list-group mb-4">
                    {data.uploads.map((upload, i) => {
                        return <li key={i} className="list-group-item">{upload.basename}</li>
                    })}
                    </ul>
                </>}
                {data.extended_file_source &&
                <>
                    <h3 className="small">Bereits hochgeladene Dateien</h3>
                    <label>Platform</label>
                    <p>{data.extended_file_source}</p>
                    <label>Anzahl gesendeter Serien und Bildanzahl</label>
                    <p>{data.extended_amount_files}</p>
                    {data.extended_vetsxl_link &&
                        <><label>VETSXL Downloadlink</label>
                        <p>{data.extended_vetsxl_link}</p></>}
                </>}
            </TabPane>
        </TabContent>
    </>
}