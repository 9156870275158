import axios from "axios";

export default async function getAttachments(link){
    return await axios.get(link+'/attachments').then((response) => {
        if(response.status === 200 && response.data && response.data.length)
            return response.data;

        return null;
    }).catch(()=> {
        return null;
    });
}