import { Link } from 'react-router-dom';
import { PasswordChange as PasswordChangeForm} from '../components/Auth/PasswordChange';
import katzenscellet from './../assets/images/Katzenskelett_transparent.png';

export default function PasswordChange() {
    return (
        <div className="inside">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Passwort ändern</h1>
                                    <p>Wenn Ihr Link gültig ist können Sie Ihr altes, vergessene Passwort ändern.</p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenscellet} alt="Katzen-Röntgen"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <PasswordChangeForm />
            </div>
            <div className="container my-4 my-lg-5 px-4 px-sm-3 px-md-2">
                <div className="row">
                    <div className="col-12 col-lg-6 px-0 px-lg-2 pe-lg-3">
                        <div className="bg-grey border-rounded p-4 h-100">
                            <h3>FAQ</h3>
                            <p>Sie haben Fragen zu unserem Service? Hier finden Sie Antworten zu verschiedenen Fragen.<br />
                            Ist Ihre Frage ist nicht dabei, dann kontaktieren Sie uns unter kontakt@vetradiologie.de</p>
                            <Link to="/faq" title="zu den FAQs" className="white-rounded-link py-1 px-3 rounded-pill">zu den Fragen</Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0 px-0 px-lg-2 ps-lg-3">
                        <div className="bg-grey border-rounded h-100 p-4">
                            <h3>Bereits registriert?</h3>
                            <p>Sie haben bereits einen Zugang. Hier geht es zum Login.</p>
                            <Link to="/" title="zum Login" className="white-rounded-link py-1 px-3 rounded-pill">zum Login</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}