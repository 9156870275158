import axios from "axios";

export default async function getCommunication(link){
    return await axios.get(link);
    // .then((response) => {
    //     if(response.status === 200)
    //         return {status: true, infos: response.data};

    //     return {status: false};
    // }).catch(() => {
    //     return {status: false};
    // })
}