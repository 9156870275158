import axios from "axios";

export default async function getRequests(filter) {

    let url = '/me/requests';

    if(filter){
        if(filter?.search && filter.search.length)
            url += "?s="+filter.search;
    }

    return await axios.get(url).then((repsonse) => repsonse.data);
}