import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown, faFlag, faEye } from '@fortawesome/pro-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

export default function RowOrder({order, getMedicalReport, showOrderDetails}){

    var date_options = { year: 'numeric', month: '2-digit', day: '2-digit'};
    var time_options = { hour: '2-digit', minute: '2-digit'};

    var created_at = new Date(order.created_at);
    var updated_at = new Date(order.updated_at);

    var status = 'In Bearbeitung';
    if(order.status === 'COMPLETED')
    {
        status = 'Befundet';
    }else if(order.status === 'CLOSED'){
        status = 'Abgeschlossen';
    }else if(order.status === 'INVOICE_SEND'){
        status = 'Rechnung versendet';
    }else if(order.status === 'CANCELED'){
        status = 'Storniert';
    }else if(order.status === 'ARCHIVED'){
        status = 'Archiviert';
    }

    var color = order.status.toLowerCase();

return <tr>
        <td>
            <span className="me-2">{order.ordernumber}</span>
            {order.importance === 'HIGH' && 
            <>
            <FontAwesomeIcon icon={faFlag} className="text-danger fs-6" id={"order-"+order.id+"-importance"}/>
            <UncontrolledTooltip placement={'top'} target={"order-"+order.id+"-importance"}>
            Dringlicher Auftrag
            </UncontrolledTooltip>
            </>
            
            }
        </td>
        <td>
            <span>{order.pet_owner}</span>
        </td>
        <td>
            <span>{order.pet_name}</span>
        </td>
        <td>
            <span>{created_at.toLocaleDateString('de-DE',date_options)}</span>
        </td>
        <td>
            <span className="me-2">{updated_at.toLocaleDateString('de-DE',date_options)}</span>
            <span className="text-muted small">{updated_at.toLocaleTimeString('de-DE',time_options)}</span>
        </td>
        <td><span className={"badge bg-"+color+" d-block"}>{ status }</span></td>
        <td>{order.price ? <p className="m-0 text-end">{order.price}</p>: <p className="m-0 text-center">-</p>}</td>
        <td className="text-end">
            {order.hasReport === 'true' &&
            <button onClick={() => getMedicalReport(order.id)} id={"order-"+order.id+"-medical_report"} className="btn px-2 py-0 table-link">
                <FontAwesomeIcon icon={faCloudArrowDown} className="fs-5" />
                <UncontrolledTooltip placement={'top'} target={"order-"+order.id+"-medical_report"}>
                Befund
                </UncontrolledTooltip>
            </button>}
            <button onClick={() => showOrderDetails(order.id)} id={"order-"+order.id+"-details"} className="btn px-2 py-0 table-link">
                <FontAwesomeIcon icon={faEye} className="fs-5" />
                <UncontrolledTooltip placement={'top'} target={"order-"+order.id+"-details"}>
                Ihre Angaben
                </UncontrolledTooltip>
            </button>
            <Link to={"/anfragen/auftrag/"+order.id+"/kommunikation"} id={"order-"+order.id+"-messages"} className="px-2 table-link">
                <i className="icon-new-chat text-primary fs-5"></i>
                <UncontrolledTooltip placement={'top'} target={"order-"+order.id+"-messages"}>
                Kommunikation
                </UncontrolledTooltip>
            </Link>
        </td>
    </tr>
}