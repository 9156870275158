import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { Navigation } from './Navigation'

export default function Layout(){
    const [menuOpen, isMenuOpen] = useState(false);

    return <>
        <header>
            <div className="inside">
                <Navigation menuOpen={menuOpen} isMenuOpen={isMenuOpen}/>
            </div>
        </header>
        <main className={menuOpen ? 'container-blur body-overflow': 'container-fluid p-0'}><Outlet /></main>
        <footer>
            <div className="inside">
                <div className="container-fluid bg-grey py-2">
                    <div className="row">
                        <div className="col-12 p-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 col-lg-4 py-1 pb-2 pb-lg-0 text-center text-lg-start">© Vetradiologie 2024</div>
                                    <div className="col-12 col-lg-4 text-center py-1 pb-3 pb-lg-0">
                                        <div className="d-flex flex-row align-items-center">
                                            <Link to="/agb" title="Zu den allgemeinen Geschäftsbedingungen">AGB</Link>
                                            <span className="mx-2">|</span>
                                            <Link to="/impressum" title="Zum Impressum">Impressum</Link>
                                            <span className="mx-2">|</span>
                                            <Link to="/datenschutz" title="Zur Datenschutzerklärung">Datenschutz</Link>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4 text-center social-contact">
                                        <a href="https://www.facebook.com/Vetradiologie" rel="noopener noreferrer" target="_blank" title="Zum Facebook-Account"><i className="icon-facebook"></i></a>
                                        <a href="https://www.instagram.com/vetradiologie" rel="noopener noreferrer" target="_blank" title="Zum Instagram-Account"><i className="icon-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
}