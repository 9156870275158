import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import getInquirySets from "../../../hooks/getInquirySets";
import saveInfos from "../../../hooks/saveInfos";
import { Tooltip } from 'reactstrap';
import { useNavigate } from "react-router-dom";

export default function Infos(props) {
    const [responses, setResponse] = useState(null);
    const [infos, setInfos] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [sets , setSets] = useState(null);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const navigate = useNavigate();
    let cancel = props.cancel;

    if(infos === null && props.inquiry)
        setInfos(props.inquiry);

    useEffect(() => {
        if(loading && sets === null) {
            getInquirySets(1).then((response) => {
                if(response.status) {
                    setSets(response.sets);
                    setLoading(false);
                }
            });
        }
    }, [loading,sets]);

    function validateMandatory(val) {
        if(!val)
            return 'Pflichtfeld';
    }

    return loading ? 
    <div className="form-placeholder">
        <div className="container bg-lightgrey mt-3 mb-0 p-4 px-4 px-sm-5 step01">
            <div className="row justify-content-center">
                <h2 className="font-green"><i className="icon-paw"></i>1. Angaben zu Tier &amp; Besitzer</h2>
                <div className="mb-2 mb-md-3 col-12 col-md-6 pr-5 pt-3">
                    <label className="form-label">Besitzer*</label>
                    <div className="placeholder-loading"></div>
                </div>
                <div className="mb-2 mb-md-3 col-12 col-md-6 pl-5 pt-1 pt-md-3">
                    <label className="form-label">Tiername*</label>
                    <div className="placeholder-loading"></div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="mb-2 mb-md-3 col-12 col-md-6 pr-5 pt-2">
                    <div className="row">
                        <div className="col-12 col-sm-6 mb-3 mb-sm-0 ">
                            <label className="form-label">Geschlecht*</label>
                    <div className="placeholder-loading"></div>
                        </div>
                        <div className="col-12 col-sm-6">
                            <label className="form-label">Geburtsjahr*</label>
                    <div className="placeholder-loading"></div>
                        </div>
                        <div className="pt-3">
                            <label className="form-check-label">Kastriert</label>
                            <div className="placeholder-loading"></div>
                        </div>
                    </div>
                </div>
                <div className="mb-2 mb-md-3 col-12 col-md-6 pl-5 pt-2">
                    <label className="form-label">Tierart*</label>
                    <div className="placeholder-loading"></div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="mb-2 mb-md-3 col-12 col-md-6 pr-5 pt-2">
                    <label className="form-label">Ihre Auftragsnummer/Kennung</label>
                    <div className="placeholder-loading"></div>
                        <div className="pt-3">
                            <label className="form-check-label">Besondere Dringlichkeit</label>
                            <div className="placeholder-loading"></div>
                        </div>
                </div>
                <div className="mb-2 mb-md-3 col-12 col-md-6 pl-5 pt-2">
                    <label className="form-label">Zuständige/r Tierarzt/Tierärztin</label>
                    <div className="placeholder-loading"></div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="mb-3 col-12 pr-5 pt-2">
                    <label className="form-label">Kurzbeschreibung*</label>
                    <div className="placeholder-loading"></div>
                </div>
            </div>
        </div>
    </div>:
    <Formik
        enableReinitialize
        initialValues={{
            pet_owner: infos?.pet_owner ??  '',
            pet_name: infos?.pet_name ??  '',
            pet_gender: infos?.pet_gender ??  '',
            pet_neutered: infos?.pet_neutered ??  false,
            pet_birthyear: infos?.pet_birthyear ??  new Date().getFullYear(),
            pet_species: infos?.pet_species ??  '',
            pet_breed: infos?.pet_breed ??  '',
            custom_id: infos?.custom_id ??  '',
            description: infos?.description ??  '',
            doctor: infos?.doctor ??  '',
            importance: infos?.importance ?? false,
            
        }}
        validate={values => {
            const errors = {};
            if(!values.pet_birthyear)
            {
                errors['pet_birthyear'] = "Pflichtfeld";
            }else if((new Date().getFullYear()) < values.pet_birthyear){
                errors['pet_birthyear'] = "Geburtsjahr muss in der Vergangenheit liegen";
            }else if(1900 > values.pet_birthyear){
                // errors['pet_birthyear'] = "Das Tier ist vor 1900 geboren? Das käme ins Guinness-Buch der Rekorde.";
            }

            if(!values.pet_species || !values.pet_species.trim()) {
                errors['pet_species'] = 'Pflichtfeld';
            }else if(!values.pet_breed || !values.pet_breed.trim()){
                errors['pet_breed'] = 'Bitte Rasse/Beschreibung angeben';
            }else if(Object.values(sets.species).includes(values.pet_breed)){
                var i = Object.values(sets.species).indexOf(values.pet_breed);
                var key = Object.keys(sets.species)[i];

                errors['pet_breed'] = "Es gibt eine Option mit der Bezeichnung '"+sets.species[key]+"'";
            }

            return errors;
        }}
        onSubmit={(values, {setSubmitting}) => {
            saveInfos(props.edit, props.baseUrl, props.id, values).then((response) => {
                if(response.status){
                    setSubmitting(true);
                    if(response.mode === 'created' || response.id){
                        navigate('/anfragen/'+response.id, {replace:false, state: {
                            message: {type: 'success', title: 'Entwurf erstellt', description: 'Entwurf wurden erfolgreich gespeichert.'}
                        }});
                    }else{
                        props.forward();
                    }
                }else{
                    setSubmitting(false);
                    setResponse(response.error);
                }
            });
        }}
    >
        {(props) => (
            <Form>
                <div className="container bg-lightgrey mt-3 mb-0 p-4 px-4 px-sm-5 step01">
                    <div className="row justify-content-center">
                        <h2 className="font-green"><i className="icon-paw"></i>1. Angaben zu Tier &amp; Besitzer</h2>
                        {responses && 
                            <div className="messages">
                                {responses.map((message, i) => {
                                    return (<span key={i} className={message.type+" mb-2"}>{message.text}</span>) 
                                })}
                            </div>
                        }
                        <div className="mb-2 mb-md-3 col-12 col-md-6 pr-5 pt-3">
                            <label htmlFor="pet_owner" className="form-label">Besitzer*</label>
                            <Field type="text" className="form-control" id="pet_owner" name="pet_owner" autoFocus required validate={validateMandatory}/>
                            <ErrorMessage name="pet_owner">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                        <div className="mb-2 mb-md-3 col-12 col-md-6 pl-5 pt-1 pt-md-3">
                            <label htmlFor="pet_name" className="form-label">Tiername*</label>
                            <Field type="text" className="form-control" id="pet_name" name="pet_name" required validate={validateMandatory}/>
                            <ErrorMessage name="pet_name">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="row">
                                <div className="mb-2 mb-md-3 col-12 col-md-6 pr-5 pt-2">
                                    <label htmlFor="pet_gender" className="form-label">Geschlecht*</label>
                                    <Field component="select" className="form-select" id="pet_gender" name="pet_gender" required >
                                        <option value=''>Bitte auswählen</option>
                                        { Object.entries(sets.pet_genders).map((val, key) => {
                                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                        })}
                                    </Field>
                                </div>
                                <div className="mb-2 mb-md-3 col-12 col-md-6 pr-5 pt-2">
                                    <label htmlFor="pet_birthyear" className="form-label">Geburtsjahr*</label>
                                    <Field type="number" min="1800" max="9999"  className="form-control" id="pet_birthyear" name="pet_birthyear" required />
                                    <ErrorMessage name="pet_birthyear">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-2 mb-md-3 col-12 pl-5 pt-2">
                                    <Field type="checkbox" className="form-check-input" id="pet_neutered" name="pet_neutered" />
                                    <label className="form-check-label user-select-none ms-1" htmlFor="pet_neutered">Kastriert</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="row">
                                <div className="mb-2 mb-md-3 col-12 pl-5 pt-2">
                                    <label htmlFor="species" className="form-label">Tierart*</label>
                                    <Field component="select" className="form-select" id="species" name="pet_species" required >
                                        <option value=''>Bitte auswählen</option>
                                        { Object.entries(sets.species).map((val, key) => {
                                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="pet_species">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-2 mb-md-3 col-12 pl-5 pt-2">
                                    <label htmlFor="pet_breed" className="form-label">
                                    {!props.values.pet_species || ['DOG','CAT','BIRD'].includes(props.values.pet_species) ?
                                    'Tierrasse' : 'Beschreibung'}*</label>
                                    <Field type="text" required id="pet_breed" name="pet_breed" className="form-control" />
                                    <ErrorMessage name="pet_breed">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 mb-md-3 col-12 col-md-6 pr-5 pt-2">
                            <label htmlFor="custom_id" className="form-label">Ihre Auftragsnummer/Kennung</label>
                            <Field type="text" className="form-control" id="custom_id" name="custom_id" />
                        </div>
                        <div className="mb-2 mb-md-3 col-12 col-md-6 pl-5 pt-2">
                            <label htmlFor="doctor" className="form-label">Zuständige/r Tierarzt/Tierärztin</label>
                            <Field type="text" className="form-control" id="doctor" name="doctor" />
                        </div>
                        <div className="mb-2 mb-md-3 col-12 pl-5">
                            <Field type="checkbox" className="form-check-input" id="importance" name="importance" />
                            <label className="form-check-label user-select-none ms-1" htmlFor="importance">Besondere Dringlichkeit</label>
                        </div>
                        <div className="mb-3 col-12 pr-5 pt-2">
                            <label htmlFor="description" className="form-label" rel="noreferrer" id="Tooltip">Vetrauliche Angaben</label>
                            <Field compoent="textarea" className="form-control" id="description" name="description" rows="3" placeholder="Textfeld mit maximal 500 Zeichen"/>
                            <ErrorMessage name="description">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                        <Tooltip
                            isOpen={tooltipOpen}
                            target="Tooltip"
                            toggle={toggle}
                        >
                            Die Angabe wird vetraulich behandelt und wird nicht in den medizinischen Befund übernommen.
                        </Tooltip>
                    </div>
                </div>
                <div className="container mb-5">
                    <div className="row">
                        <div className="col-12 text-end pt-3">
                            <button type="button" onClick={cancel} className="py-1 px-3 rounded-pill btn-grey mb-3 me-2"><i className="icon-left-circle i-right"></i>Abbrechen</button>
                            <button type="submit" className="py-1 px-3 rounded-pill btn-green mb-3" >Speichern</button>
                        </div>
                    </div>
                </div>
            </Form>
        )}
    </Formik>
}