import { useQuery } from "@tanstack/react-query";
import getMessage from "../../hooks/getMessage";
import { Loading } from "../App/Loading";

export default function Message({id, url}){
    const date_options = { year: '2-digit', month: '2-digit', day: '2-digit' };

    const { data, isLoading } = useQuery({
        queryKey: ['communication-message', {url, id}],
        queryFn: async() => await getMessage(url,id),
        enabled: !!url && !!id
    });

    if(isLoading)
        return <Loading />;

    return <div className="bg-white p-3" id={"v-pills-tabContent-"}>
    <div className="d-flex flex-row align-items-center">
        <i className="icon-person-pin color-primary d-inline fs-5"></i>
        <p className="mb-0 ms-2">{data.message.contact}</p>
        <p className="ms-auto mb-0 me-3">
            {(new Date(data.message.created_at)).toLocaleTimeString('de-DE',{hour:'2-digit', minute:'2-digit'})}
            <span className="small ms-3">{(new Date(data.message.created_at)).toLocaleDateString('de-DE',date_options)}</span>
        </p>
        {data.message.direction === 'OUT' ?
            (data.message.read === 'FALSE' ?
            <i className="d-lg-block icon-mailbox"></i> :
            <svg width='21px' height='21px' fill="#02bec4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M250.5 0h68.6c9.5 0 17.1 7.7 17.1 17.1V160h68.3c17.8 0 26.7 21.5 14.1 34.1l-119.4 120c-7.9 7.9-20.9 7.8-28.6-.3L157 193.8c-12.1-12.7-3.1-33.8 14.5-33.8h61.9V17.1c0-9.4 7.7-17.1 17.1-17.1zm315 338.9l-94.6-118.2c-4.5-5.6-13-6-18-.9l-28.1 28.9c-4.2 4.3-4.5 11.1-.8 15.9l44.3 55.4H376l-32 64H232l-32-64h-92.4l43.8-54.7c3.7-4.7 3.5-11.4-.6-15.7l-27.6-29.5c-5-5.3-13.6-5-18.1.7l-94.4 118c-7 8.6-10.7 19.1-10.7 30V464c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48v-95.2c0-10.9-3.7-21.4-10.5-29.9z"/></svg>):
        <svg width='21px' height='21px' fill="#02bec4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M576 368.8V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48v-95.2c0-10.9 3.7-21.5 10.5-30l94.4-118c4.6-5.7 13.1-6 18.1-.7l27.6 29.5c4.1 4.4 4.3 11 .6 15.7L107.6 320H200l32 64h112l32-64h92.4l-44.3-55.4c-3.8-4.7-3.5-11.5.8-15.9l28.1-28.9c5-5.2 13.5-4.8 18 .9l94.6 118.2c6.7 8.5 10.4 19 10.4 29.9zm-233.4-65.9V160h61.9c17.6 0 26.6-21.1 14.5-33.8L305.3 6.2c-7.7-8.1-20.7-8.3-28.6-.3l-119.4 120c-12.6 12.6-3.7 34.1 14.1 34.1h68.3v142.9c0 9.5 7.7 17.1 17.1 17.1h68.6c9.5 0 17.2-7.7 17.2-17.1z"/></svg>}
    </div>
    <p className="fs-4 py-2 mb-0">
        {data.message.message}
    </p>
</div>

}