import { Link } from 'react-router-dom';
import katzenskellet from './../assets/images/Katzenskelett_transparent.png'
export default function NotFoundPage() {
    return (
        <div className="inside">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Seite nicht gefunden</h1>
                                    <p></p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenskellet} alt="Katzen-Röntgen" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container px-4">
                <div className="row">
                    <div className="col-12 col-md-10 p-0 px-2 px-sm-0">
                        <div>
                            <p>Die von Ihnen gesuchte Seite konnte nicht gefunden werden. Kontakieren Sie uns, wenn Sie Rückfragen haben oder Sie wissen wollen, was am Besten zu tun ist:</p>
                            <a href="mailto:kontakt@vetradiologie.de" >E-Mail an kontakt@vetradiologie.de</a><br />
                            <Link to="/startseite" className='mt-4 btn green-rounded-link'>Zur Startseite</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-footer"></div>
        </div>
    );
}