import { useAuth } from "../Auth/AuthProvider";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import checkUserEmail from "../../hooks/checkUserEmail";
import getUserSets from "../../hooks/getUserSets";
import saveProfile from "../../hooks/saveProfile";
import { Loading } from "../App/Loading";
import { Toast, ToastBody, ToastHeader } from "reactstrap";

export default function Profile(){
    const auth = useAuth();

    const [messages, setMessages] = useState(null);
    const [response, setResponse] = useState({});
    const [loading, setLoading] = useState(true);
    const [sets, setSet] = useState(null);
    const [emailCache, setEmailCache] = useState(null);

    useEffect(() => {
        if(response?.isOpen && response?.isOpen === true){
            setTimeout(() => {
                setResponse({...response, isOpen: false});
            },4000);
        }

        if(loading && !sets){
            getUserSets().then((response) => {
                if(response.status){
                    setSet(response.sets);
                    setLoading(false);
                }
            })
        }
    },[loading, response, sets]);

    async function validateEmail(email) {
        if (!email) {
            return 'Pflichtfeld';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
        ){
            return 'Ungültige E-Mail-Addresse';
        }else if(emailCache !== email){
            return await checkUserEmail(email, auth.profile.user.id).then((response) => {
                if(response.status){
                    setEmailCache(email);
                    return null;
                }else{
                    return response.message;
                }
            });
        }else{
            return null;
        }
    };

    return loading ?
    <Loading />  :
    <>
    <Toast isOpen={response && response.isOpen ? true : false} className='position-absolute' style={{top: '10px', left: "calc(50% - 175px)"}}>
        <ToastHeader icon={<i className={"icon "+(response.type === 'success' ? 
                                                    "icon-check text-success" : 
                                                    "icon-cancel text-danger")}></i>}>
            {response?.title}
        </ToastHeader>
        <ToastBody>
            {response?.description}
        </ToastBody>
    </Toast>
    <Formik
        enableReinitialize
        initialValues={{
            title: auth.profile.user?.title ?? '',
            address: auth.profile.user?.address ?? '',
            firstname: auth.profile.user?.firstname ?? '',
            lastname: auth.profile.user?.lastname ?? '',
            email: auth.profile.user?.email ?? '',
            phone: auth.profile.user?.phone ?? '',
            company_position: auth.profile.user?.company_position ?? '',
            password: '',
            confirm_password: ''
        }}
        validate={values => {
            const errors = {};
            if (!values.firstname) {
                errors.firstname = 'Pflichtfeld';
            }

            if (!values.lastname) {
                errors.lastname = 'Pflichtfeld';
            }

            if (!values.address) {
                errors.address = 'Pflichtfeld';
            }

            if(!values.company_position)
                errors.company_position = 'Pflichtfeld';
            if(!(values.company_position in sets.company_positions))
                errors.companyPositions =  'Kein gültige Anrede ausgewählt';

            if(values.password && values.password !== values.confirm_password)
                errors.password = 'Passwörter stimmen nicht überein'

            if(values.confirm_password && values.password !== values.confirm_password)
                errors.confirm_password = 'Passwörter stimmen nicht überein'
            

            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            saveProfile(values).then((response)=>{
                if(response.status){
                    setSubmitting(false);
                    setResponse({isOpen:true, type: 'success', title: 'Änderungen gespeichert', description: 'Änderungen wurden gespeichert und Profil aktualisiert'});
                    auth.update();
                }else{
                    setResponse(response.error);
                    if(response?.messages && response?.messages.length)
                        setMessages(response.messages);
                    setSubmitting(false);
                }
            });
        }}
    >
        {(props) => (
            <Form method="POST">
                {messages && 
                <div className="messages">
                    {messages.map((message, i) => {
                        return (<span key={i} className={message.type+" mb-2"}>{message.text}</span>) 
                    })}
                </div>}
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-group">
                            <label htmlFor="title">Titel</label>
                            <Field type="text" name="title" placeholder="Titel" className="form-control" />
                            <ErrorMessage name="title">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group mt-md-0 mt-3">
                            <label htmlFor="address">Anrede*</label>
                            <Field as="select" name="address" placeholder="Anrede" className="form-select" >
                                <option value=''>Bitte auswählen</option>
                                <option value='FEMALE'>Frau</option>
                                <option value='MALE'>Herr</option>
                                <option value='DIVERS'>Divers</option>
                            </Field>
                            <ErrorMessage name="address">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group mt-3">
                            <label htmlFor="firstname">Vorname*</label>
                            <Field type="text" name="firstname" placeholder="Vorname" className="form-control" />
                            <ErrorMessage name="firstname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group mt-3">
                            <label htmlFor="lastname">Nachname*</label>
                            <Field type="text" name="lastname" placeholder="Nachname" className="form-control" />
                            <ErrorMessage name="lastname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group mt-3">
                            <label htmlFor="email">E-Mail-Adresse*</label>
                            <Field type="email" name="email" placeholder="E-Mail-Adresse" className="form-control" validate={validateEmail} />
                            <ErrorMessage name="email">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group mt-3">
                            <label htmlFor="phone">Telefonnummer</label>
                            <Field type="phone" name="phone" placeholder="Telefonnummer" className="form-control" />
                            <ErrorMessage name="phone">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="form-group mt-3">
                            <label htmlFor="company_position" className="form-label">Aufgabenbereich*</label>
                            <Field component="select" className="form-select" name="company_position" id="company_position" required>
                                    <option value=''>Bitte auswählen</option>
                                    { Object.entries(sets.company_positions).map((val, key) => {
                                    return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                })}
                            </Field>
                            <ErrorMessage name="company_position">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <h4 className="text-uppercase fw-bold">Neues Passwort</h4>
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <label htmlFor="password" className="form-label">Passwort</label>
                                <Field type="password" className="form-control" id="password" name="password" />
                                <ErrorMessage name="password">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                            <div className="col-md-6 col-12">
                                <label htmlFor="confirm_password" className="form-label">Passwort bestätigen</label>
                                <Field type="password" className="form-control" id="confirm_password" name="confirm_password" />
                                <ErrorMessage name="confirm_password">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-end mt-3">
                    <button type="submit" disabled={props.isSubmitting || props.isValid !== true} className="py-1 px-3 rounded-pill btn-green">Aktualisieren</button>
                </div>
            </Form>
        )}
    </Formik>
    </>
}