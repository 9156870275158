import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";
import some from 'lodash/some';
import { register } from "../../hooks/register";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import AGB from "../App/AGB";
import {DataPrivacy} from "../App/DataPrivacy";

export default function RegistrationForm() {
    const [response, setResponse] = useState(null);
    const [registered, setRegistered] = useState(false);
    const [agbModalOpen, isAgbModalOpen] = useState(false);
    const [dataPrivayModalOpen, isDataPrivacyModalOpen] = useState(false);

    const data = useLoaderData();
    const countries = data.countries;
    const salutations = data.salutations;
    const types = data.types;
    const companyPositions = data.company_positions;

    function validateEmail(email) {
        if (!email) {
            return 'Pflichtfeld';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            return 'Ungültige E-Mail-Addresse';
        } else {
            return null;
        }
    };

    function validateMandatory(val) {
        if (!val)
            return 'Pflichtfeld';
    }

    const formInitialValues = {
        company: {
            name: '',
            type: '',
            street: '',
            housenumber: '',
            postal: '',
            place: '',
            country: '',
            title: '',
            address: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            vat: '',
            sepa: false,
        },
        client: {
            title: '',
            address: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
            password_confirmation: '',
            phone: '',
        }
    };

    return registered ?
        <section className="container">
            <h2>Erfolgreich Registriert</h2>
            <p>Sie sind registriert. Sobald Sie freigeschaltet wurden, bekommen Sie weitere Informationen</p>
        </section> :
        <>
            <Modal isOpen={agbModalOpen} toggle={() => isAgbModalOpen(false)} size="xl">
                <ModalHeader toggle={() => isAgbModalOpen(false)}>ALLGEMEINE GESCHÄFTSBEDINGUNGEN</ModalHeader>
                <ModalBody>
                    <AGB />
                </ModalBody>
            </Modal>
            <Modal isOpen={dataPrivayModalOpen} toggle={() => isDataPrivacyModalOpen(false)} size="xl">
                <ModalHeader toggle={() => isDataPrivacyModalOpen(false)}>DATENSCHUTZERKLÄRUNG</ModalHeader>
                <ModalBody style={{padding:'40px'}}>
                    <DataPrivacy />
                </ModalBody>
            </Modal>
            <Formik
                enableReinitialize
                initialValues={formInitialValues}
                validate={values => {
                    const errors = {};
                    errors['company'] = {
                        'email': validateEmail(values.company.email),
                        'type': (function (type) {
                            if (!type)
                                return 'Pflichtfeld';
                            if (!(type in types))
                                return 'Kein gültiger Typ ausgewählt';
                            return null;
                        })(values.company.type),
                        'address': (function (address) {
                            if (!address)
                                return 'Pflichtfeld';
                            if (!(address in salutations))
                                return 'Kein gültige Anrede ausgewählt';
                            return null;
                        })(values.company.address),
                        'country': (function (country) {
                            if (!country)
                                return 'Pflichtfeld';
                            if (!(country in countries))
                                return 'Kein gültiges Land ausgewählt';
                            return null;
                        })(values.company.country)
                    }

                    errors['client'] = {
                        'email': validateEmail(values.client.email),
                        'address': (function (address) {
                            if (!address)
                                return 'Pflichtfeld';
                            if (!(address in salutations))
                                return 'Kein gültige Anrede ausgewählt';
                            return null;
                        })(values.client.address),
                        'company_position': (function (company_position) {
                            if (!company_position)
                                return 'Pflichtfeld';
                            if (!(company_position in companyPositions))
                                return 'Kein gültige Anrede ausgewählt';
                            return null;
                        })(values.client.company_position),
                        'password': (function (password, confirmation) {
                            if (!password)
                                return 'Pflichtfeld';
                            if (confirmation && password !== confirmation)
                                return 'Passwörter stimmen nicht überein'
                            return null;
                        })(values.client.password, values.client.password_confirmation),
                        'password_confirmation': (function (confirmation, password) {
                            if (!confirmation)
                                return 'Pflichtfeld';
                            if (confirmation && password !== confirmation)
                                return 'Passwörter stimmen nicht überein'
                            return null;
                        })(values.client.password_confirmation, values.client.password)
                    }
                    return some(errors.client) || some(errors.company) ? errors : null;
                }}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    register(values.company, values.client).then((response) => {
                        if (response === true) {
                            setRegistered(true);
                            setSubmitting(true);
                        } else {
                            setRegistered(false);
                            setSubmitting(false);
                            setResponse(response);
                        }
                    });
                }}
            >
                {(props) => (
                    <Form >
                        {response &&
                            <div className="container messages">
                                {response.map((message, i) => {
                                    return (<span key={i} className={message.type + " mb-2"}>{message.text}</span>)
                                })}
                            </div>
                        }
                        <div className="container bg-lightgrey px-1 px-sm-4">
                            <div className="row py-4 px-4">
                                <h2 className="font-green">Praxisdaten</h2>
                                <div className="mb-3 col-12 col-md-6 pt-3">
                                    <label htmlFor="company-name" className="form-label">Praxisname*</label>
                                    <Field className="form-control" id="company-name" name="company.name" required validate={validateMandatory} />
                                    <ErrorMessage name="company.name">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-md-3">
                                    <label htmlFor="company-type" className="form-label">Art*</label>
                                    <Field component="select" className="form-select" id="company-type" name="company.type" required >
                                        <option value=''>Bitte auswählen</option>
                                        {Object.entries(types).map((val, key) => {
                                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="company.type">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-md-1">
                                    <label htmlFor="company-street" className="form-label">Straße*</label>
                                    <Field className="form-control" id="company-street" name="company.street" required validate={validateMandatory} />
                                    <ErrorMessage name="company.street">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-md-1">
                                    <label htmlFor="company-housenumber" className="form-label">Hausnummer*</label>
                                    <Field className="form-control" id="company-housenumber" name="company.housenumber" validate={validateMandatory} />
                                    <ErrorMessage name="company.housenumber">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-postal" className="form-label">PLZ*</label>
                                    <Field className="form-control" id="company-postal" name="company.postal" required validate={validateMandatory} />
                                    <ErrorMessage name="company.postal">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-place" className="form-label">Ort*</label>
                                    <Field className="form-control" id="company-place" name="company.place" required validate={validateMandatory} />
                                    <ErrorMessage name="company.place">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-country" className="form-label">Land*</label>
                                    <Field component="select" className="form-select" id="company-country" name="company.country" required>
                                        <option value=''>Bitte auswählen</option>
                                        {Object.entries(countries).map((val, key) => {
                                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="company.country">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-phone" className="form-label">Telefon (Zentrale)</label>
                                    <Field className="form-control" id="company-phone" name="company.phone" />
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-vat" className="form-label">UST-ID</label>
                                    <Field className="form-control" id="company-vat" name="company.vat" />
                                </div>
                            </div>
                        </div>
                        <div className="container bg-lightgrey border-top-green px-1 px-sm-4">
                            <div className="row py-4 px-4">
                                <h2 className="font-green mt-2">Rechnungsempfänger:in</h2>
                                <div className="mb-3 col-12 col-md-6 pt-3 pt-md-0 pt-lg-3">
                                    <label htmlFor="company-address" className="form-label">Anrede*</label>
                                    <Field component="select" className="form-select" name="company.address" id="company-address" required>
                                        <option value=''>Bitte auswählen</option>
                                        {Object.entries(salutations).map((val, key) => {
                                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="company.address">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-title" className="form-label">Titel</label>
                                    <Field className="form-control" id="company-title" name="company.title" />
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-firstname" className="form-label">Vorname*</label>
                                    <Field className="form-control" id="company-firstname" name="company.firstname" required validate={validateMandatory} />
                                    <ErrorMessage name="company.firstname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-lastname" className="form-label">Nachname*</label>
                                    <Field className="form-control" id="company-lastname" name="company.lastname" required validate={validateMandatory} />
                                    <ErrorMessage name="company.lastname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <label htmlFor="company-email" className="form-label">E-Mail Adresse (Rechnungsversand)*</label>
                                    <Field className="form-control" id="company-email" name="company.email" required />
                                    <ErrorMessage name="company.email">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pt-0 pt-lg-3">
                                    <div className="d-flex align-items-center h-100">
                                        <div className="form-check vertical-middle">
                                            <Field type="checkbox" className="form-check-input" id="sepa" name='company.sepa' />
                                            <label className="form-check-label" htmlFor="sepa">Bezahlung über SEPA Lastschriftenverfahren</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container bg-lightgrey border-top-green px-1 px-sm-4">
                            <div className="row pt-4 px-4">
                                <h2 className="font-green">Hauptbenutzer:in</h2>
                                <div className="mb-3 col-12 col-md-6 pe-5 pt-3 pt-md-0 pt-lg-3">
                                    <label htmlFor="client-address" className="form-label">Anrede*</label>
                                    <Field component="select" className="form-select" name="client.address" id="client-address" required>
                                        <option value=''>Bitte auswählen</option>
                                        {Object.entries(salutations).map((val, key) => {
                                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="client.address">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 ps-5 pt-0 pt-lg-3">
                                    <label htmlFor="client-title" className="form-label">Titel</label>
                                    <Field className="form-control" id="client-title" name="client.title" />
                                </div>
                                <div className="mb-3 col-12 col-md-6 pe-5 pt-0 pt-lg-3">
                                    <label htmlFor="client-firstname" className="form-label">Vorname*</label>
                                    <Field className="form-control" id="client-firstname" name="client.firstname" required validate={validateMandatory} />
                                    <ErrorMessage name="client.firstname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 ps-5 pt-0 pt-lg-3">
                                    <label htmlFor="client-lastname" className="form-label">Nachname*</label>
                                    <Field className="form-control" id="client-lastname" name="client.lastname" required validate={validateMandatory} />
                                    <ErrorMessage name="client.lastname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pe-5 pt-0 pt-lg-3">
                                    <label htmlFor="client-email" className="form-label">E-Mail Adresse*</label>
                                    <Field className="form-control" id="client-email" name="client.email" required />
                                    <ErrorMessage name="client.email">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 ps-5 pt-0 pt-lg-3">
                                    <label htmlFor="client-phone" className="form-label">Telefon</label>
                                    <Field className="form-control" id="client-phone" name="client.phone" />
                                    <ErrorMessage name="client.phone">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 pe-5 pt-0 pt-lg-3">
                                    <label htmlFor="client-password" className="form-label">Passwort*</label>
                                    <Field type="password" className="form-control" id="client-password" name="client.password" required />
                                    <ErrorMessage name="client.password">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-12 col-md-6 ps-5 pt-0 pt-lg-3">
                                    <label htmlFor="client-password-confirmation" className="form-label">Passwort bestätigen*</label>
                                    <Field type="password" className="form-control" id="client-password-confirmation" name="client.password_confirmation" required />
                                    <ErrorMessage name="client.password_confirmation">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="mb-3 col-6 pe-5 pt-3 pt-md-0 pt-lg-3">
                                    <label htmlFor="client-company_position" className="form-label">Aufgabenbereich*</label>
                                    <Field component="select" className="form-select" name="client.company_position" id="client-company_position" required>
                                        <option value=''>Bitte auswählen</option>
                                        {Object.entries(companyPositions).map((val, key) => {
                                            return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                        })}
                                    </Field>
                                    <ErrorMessage name="client.company_position">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                                </div>
                                <div className="col-6 ps-5 pt-0 align-self-center mt-0 mt-md-4">
                                    <Field type="checkbox" className="form-check-input mt-4" id="agb" name='client.data-privacy' required />
                                    <label className="form-check-label ps-3 mt-3" htmlFor="agb">
                                        <small>Ich habe die <button type="button" className="simple-green border-0 m-0 p-0" onClick={() => isAgbModalOpen(true)} >AGB</button> & <button type="button" className="simple-green border-0 m-0 p-0" onClick={() => isDataPrivacyModalOpen(true)} >Datenschutzbestimmungen</button> gelesen<br /> und akzeptiere diese*</small></label>
                                </div>
                            </div>
                            <div className="row justify-content-center align-items-end px-4 pb-4">
                                <div className="col-6 text-center">
                                    <button type="submit" className="my-3 py-1 px-3 rounded-pill btn-green">Jetzt registrieren</button>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>;
}