import katzenskellet from './../assets/images/Katzenskelett_transparent.png'
export default function LegalNotice() {
    return (
        <div className="inside">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Impressum</h1>
                                    <p>Angaben gemäß § 5 TMG</p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenskellet} alt="Katzen-Röntgen" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container px-4">
                <div className="row">
                    <div className="col-12 col-md-10 p-0 px-2 px-sm-0">
                        <div>
                            <h1>Impressum</h1>

                            <h2>Vertreten durch</h2>
                            <p>Priv. – Doz. Dr. med. vet. Antje Hartmann <br />
                                Fachtierärztin für Radiologie und andere bildgebende Verfahren; Diplomate ECVDI; European
                                Specialist in Veterinary Diagnostic Imaging <br />
                                Berufsbezeichnung Tierarzt und Fachtierarzt verliehen von der Landestierärztekammer Hessen (Deutschland).
                                Berufsbezeichnung Diplomat verliehen Cambridge, England.
                            </p>

                            <h2>Kontakt</h2>
                            <p>a.hartmann@vetradiologie.de <br />
                                Adresse: Schützenpfad 4, 61479 Glashütten, Deutschland
                            </p>

                            <h2>Umsatzsteuer-ID</h2>
                            <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: <br />
                                DE281 057 893
                            </p>

                            <h2>Zuständige Kammer</h2>
                            <p>Mitglied der Landestierärztekammer Hessen (www.ltk-hessen.de) <br />
                                Bahnhofstraße 13 <br />
                                65527 Niedernhausen <br />
                                Telefon: 06127/9075-0 <br />
                                Telefax: 06127 9075-23 <br />
                            </p>

                            <p>Berufshaftpflichtversicherung <br />
                                AXA Versicherungs AG, 53113 Bonn
                            </p>

                            <p>Angaben nach §3 DL-InfoV <br />
                                Für die vetradiologie.de gelten die folgenden berufsrechtlichen Regelungen:
                            </p>

                            <p>Bundes-Tierärzteordnung (BTÄO) in der Fassung der Bekanntmachung vom 20.11.1981 (BGBI. I S. 1193), zuletzt durch
                                die Verordnung vom 11.12.2007 (BGBI. I S. 2882) geändert. <br />
                                http://www.gesetze-im-internet.de/bt_o/BJNR004160965.html
                            </p>

                            <p>Heilberufsgesetz Hessen (Heilberufsgesetz) in der Fassung vom 07.02.2003 (GVBI. I S. 66, 242), zuletzt durch die
                                Verordnung vom 15.12.2009 (GVBI. I S. 716) geändert <br />
                                https://www.laekh.de/images/Aerzte/Rund_ums_Recht/Rechtsquellen/heilberufsgesetz.pdf
                            </p>

                            <p>Berufsordnung der Landestierärztekammer Hessen (Berufsordnung) in der Fassung vom 03.11.1993, zuletzt geändert
                                durch Beschluss der Delegiertenversammlung am 19.11.2008, gültig seit 01.04.2009. <br />
                                http://www.ltk-hessen.de/index.php?id=295
                            </p>

                            <h2>Schlichtungsstelle</h2>
                            <p>Der Schlichtungsausschuss der Landestierärztekammer Hessen hat die Aufgabe, bei Streitigkeiten zwischen
                                Tierärzten und bei solchen Streitigkeiten zwischen Tierärzten und Dritten, die sich aus der tierärztlichen
                                Berufstätigkeit ergeben, eine Schlichtung zu versuchen. Er wird von Amts wegen oder auf Antrag tätig, soweit
                                Dritte beteiligt sind, aber nur auf deren Antrag oder mit deren ausdrücklicher Zustimmung.
                            </p>

                            <p>Tierärztliche Schlichtungs- und Schiedsordnung der Landestierärztekammer Hessen. Die Schlichtungsstelle ist über
                                die Geschäftsstelle der LTK Hessen zu erreichen:
                            </p>

                            <p>Landestierärztekammer Hessen <br />
                                Bahnhofstr. 13 <br />
                                65527 Niedernhausen <br />
                                Telefon: 06127 – 90 75 0
                            </p>

                            <h2>Rechtshinweis</h2>
                            <p>Bilder, Fotos, Grafiken, Inhalt und Struktur dieser Internetseiten sind urheberrechtlich geschützt. Die
                                Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen oder
                                Bildmaterial ist nicht gestattet.
                            </p>

                            <h2>Haftungshinweis</h2>
                            <p>Der Inhalt der Internetseiten wurde sorgfältig geprüft. Die Daten können sich jedoch inzwischen verändert haben.
                                Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten
                                Informationen kann daher nicht übernommen werden.
                            </p>
                            <p>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
                                können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
                                stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                                Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                                Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
                                derartige Links umgehend entfernen.
                            </p>
                            <h2>Gestaltung und Programmierung</h2>
                            <p>Sven Meser <br />
                                pmode Sven Meser e.K. <br />
                                Auf der Lind 8 <br />
                                65529 Waldems
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-footer"></div>
        </div>
    );
}