import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faPencil, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

export default function RowDraft({draft, deleteRequest}){

    var date_options = { year: 'numeric', month: '2-digit', day: '2-digit'};
    var time_options = { hour: '2-digit', minute: '2-digit'};

    var created_at = new Date(draft.created_at);
    var updated_at = new Date(draft.updated_at);


    return <tr>
        <td>
            <span className="me-2">{draft.id}</span>
            {draft.importance && 
            <>
                <FontAwesomeIcon icon={faFlag} id={"draft-"+draft.id+"-importance"} className="text-danger fs-6" />
                <UncontrolledTooltip placement={'top'} target={"draft-"+draft.id+"-importance"}>
                Dringlicher Entwurf
                </UncontrolledTooltip>
            </>
            }
        </td>
        <td>
            <span>{draft.pet_owner}</span>
        </td>
        <td>
            <span>{draft.pet_name}</span>
        </td>
        <td>
            <span>{created_at.toLocaleDateString('de-DE',date_options)}</span>
        </td>
        <td>
            <span className="me-2">{updated_at.toLocaleDateString('de-DE',date_options)}</span>
            <span className="text-muted small">{updated_at.toLocaleTimeString('de-DE',time_options)}</span>
        </td>
        <td><span className="badge bg-draft d-block">Entwurf</span></td>
        <td className="text-center">-</td>
        <td className="text-end">
            <Link to={"/anfragen/"+draft.id} id={"draft-"+draft.id+"-edit"} className="px-2 table-link">
                <FontAwesomeIcon icon={faPencil} className="fs-5" />
                <UncontrolledTooltip placement={'top'} target={"draft-"+draft.id+"-edit"}>
                Bearbeiten
                </UncontrolledTooltip>
            </Link>
            <button onClick={() => deleteRequest.mutate(draft.id, false,false)} id={"draft-"+draft.id+"-delete"} className="btn py-0 px-2 table-link">
                <FontAwesomeIcon icon={faTrashAlt}  className="fs-5" />
                <UncontrolledTooltip placement={'top'} target={"draft-"+draft.id+"-delete"}>
                Löschen
                </UncontrolledTooltip>
            </button>
        </td>
    </tr>
}