// https://fileinfo.com/filetypes/
export default function getAllowedFileExtensions(withMimeType){
    if(withMimeType){
        
        return [
            //images
            'image/jpg',
            'image/jxl',
            'image/jpeg',
            'image/jfif',
            'image/png',
            'image/svg+xml',
            'image/acr', //american college of radiology file
            'image/bif',
            'image/bmp',
            'image/dcm',
            '*/dicom,.dcm, image/dcm, */dcm, .dicom',
            'image/fpg',
            'image/gif',
            'image/icon',
            'image/odi',
            'image/pov',
            'image/tiff',
            'image/wmp',
            'image/webp',
        
            //text
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'application/pdf',
        
            'application/vnd.oasis.opendocument.text',
            'text/plain',
            'text/csv',
            'application/xml',
        
            //video
            'video/avi',
            'video/3gp',
            'video/mpg',
            'video/mp4',
            'video/mov',
            'video/wmv',
        
            //folders
            'application/x-tar',
            'application/tar.gz',
            'application/zip',
            'application/zipx',
            'application/x-7z-compressed',
            'application/vnd.rar',
        ];

    }

    return [
        //images
        'jpg',
        'jxl',
        'jpeg',
        'jfif',
        'png',
        'svg',
        'acr', //american college of radiology file
        'bif',
        'bmp',
        'dcm',
        'fpg',
        'gif',
        'icon',
        'odi',
        'pov',
        'tif',
        'tiff',
        'wmp',
        'webp',

        //text
        'doc',
        'docx',
        'ppt',
        'pptx',
        'xlsx',
        'xls',
        'pdf',

        'odt',
        'txt',
        'csv',
        'xml',

        //video
        'avi',
        '3gp',
        'mpg',
        'mp4',
        'mov',
        'wmv',

        //folders
        'tar',
        'tar.gz',
        'zip',
        'zipx',
        '7z',
        'rar',
    ];

}