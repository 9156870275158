import { Link, Outlet } from "react-router-dom";
import { useAuth } from "../components/Auth/AuthProvider";
import katzenscellet from "./../assets/images/Katzenskelett_transparent.png"

export default function Requests() {
    const auth = useAuth();
    return (
        <div className="inside position-relative">
            <div className="container-fluid bg-green my-3 position-relative overflow-hidden">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-12 col-sm-10 col-lg-8 col-xl-7 p-0 px-2 px-sm-0">
                                    <h1>Anfragen</h1>
                                    <p>Übersicht über Ihre Anfragen und deren Status.</p>
                                </div>
                                <div className="d-none d-sm-block col-0 col-sm-2 col-lg-4 col-xl-3 offset-xl-2 p-0 us-katze">
                                    <img src={katzenscellet} alt="Katzen-Röntgen" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4"><Outlet /></div>
            
            <div className="container-fluid my-4 overflow-hidden">
                <div className="row px-2 px-sm-4 px-lg-0">
                    <div className="col-12 col-md-10 offset-md-1 offset-lg-0 col-lg-6 px-0">
                        <div className="bg-grey-left px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 pt-4 pb-4 col-lg-10 col-xxl-9 produktreihe pt-4 pe-3 pe-lg-0 ps-3 ps-lg-5 pb-0">
                                {auth.hasRight() || auth.hasRole('ACCOUNTING') ?
                                <>
                                    <h3>Rechnungen</h3>
                                    <p>Hier finden Sie eine Übersicht über alle Ihre Rechnungen.</p>
                                    <Link to="/rechnungen" title="Zu den Rechnungen" className="white-rounded-link py-1 px-3 rounded-pill">zu den Rechnungen</Link>
                                </>:
                                <>
                                    <h3>Öffnungszeiten</h3>
                                    <p>Unsere Öffnungszeiten sind<br/>
                                        Montag bis Freitag 09:00 bis 18:00 Uhr<br />
                                        In dieser Zeit erhalten Sie innerhalb von 24 Stunden nach Erhalt aller erforderlichen Unterlagen Ihren Befund.</p>
                                </>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 offset-lg-0 col-lg-6 px-0 mt-4 mt-lg-0">
                        <div className="bg-grey-right px-4 px-lg-0">
                            <div className="row">
                                <div className="col-12 pt-4 pb-4 col-lg-10 col-xxl-9 produktreihe pt-4 pe-3 pe-lg-0 ps-3 ps-lg-5 pb-0">
                                <h3>Fragen und Antworten</h3>
                                <p>Sie haben Fragen zu unserem Service? Hier finden Sie Antworten zu verschiedenen Fragen.<br/>
Ihre Frage ist nicht dabei, dann kontaktieren Sie uns unter kontakt@vetradiologie.de</p>
                                <Link to="/faq" title="Zu den FAQ" className="white-rounded-link py-1 px-3 rounded-pill">zu den FAQ</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-footer"></div>
        </div>
    );
}