import axios from "axios";

export default async function getInquirySets(step, id){
    let url = '/me/requests/steps/'+step+'/sets';

    if(id && id !== undefined && id !== null){
        url += '/'+id;
    }

    return await axios.get(url).then((response) => {
        if(response.status === 200)
            return {status: true, sets: response.data};

        return {status:false};
    }).catch(() => {
        return {status:false};
    });
}