import axios from "axios"

export default async function getMessage(link, id){
    return await axios.get(link+'/messages/'+id)
    .then((response) => {
        if(response.status === 200 && response.data)
            return {status:true, message: response.data};

        return {status:false};
    }).catch(() =>{
        return {status: false};
    });
}