import vid from './../assets/videos/vetradiologie.mp4'
import dogscellet from './../assets/images/Hundeskelett_2_transparent.png'
import hartmann from './../assets/images/hartmann.png'
import { Login } from '../components/Auth/Login';
import { Link } from 'react-router-dom';
import { useAuth } from '../components/Auth/AuthProvider';
export default function Home() {
    const auth = useAuth();

    return <div className="inside">
        <div className="container-fluid my-3" style={{backgroundColor: "#FFFFF"}}>
            <div className="row">
                <div className="col-12 p-0 text-center">
                    <video playsInline autoPlay muted loop style={{width: "auto", height: "auto", maxWidth:"100%"}}>
                        <source src={vid} />
                    </video>
                </div>
            </div>
        </div>
        <div className="container-fluid mt-2 mb-5 my-lg-5 position-relative">
            <div className="square-grey position-absolute bg-lightgrey"></div>
            <div className="row">
                <div className="col-12 p-0">
                    <div className="container pt-5">
                        {auth.logged === true && auth.profile !== null?
                            <div className="row">
                                <div className="col-12 col-lg-6  mt-4 mt-lg-0 px-0 px-lg-2 ps-lg-3">
                                    <div className="bg-grey border-rounded h-100 p-4">
                                        <h3>{auth.profile.company.name}</h3>
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <p><small className='fw-bold'>ADDRESSE:</small><br />
                                                    {auth.profile.company.street} {auth.profile.company.housenumber}<br />
                                                    {auth.profile.company.postal} {auth.profile.company.place}</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <p><small className='fw-bold'>RECHNUNGSEMPFÄNGER:</small><br />{auth.profile.company.email}</p>
                                                <p><small className='fw-bold'>TELEFONNUMMER:</small><br />{auth.profile.company.phone ? auth.profile.company.phone : '-'}</p>
                                            </div>
                                        </div>
                                        <Link to="/dashboard" title="Mein Bereich" className="white-rounded-link py-1 px-3 rounded-pill">Mein Bereich</Link>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6  mt-4 mt-lg-0 px-0 px-lg-2 ps-lg-3">
                                    <div className="bg-grey border-rounded h-100 p-4">
                                        <h3>{auth.profile.user.username}</h3>
                                        <p>{auth.profile.user.firstname} {auth.profile.user.lastname}<br />
                                            <small className='fw-bold'>E-MAIL:</small> {auth.profile.user.email}<br />
                                            <small className='fw-bold'>TELEFONNUMMER:</small> {auth.profile.user.phone ? auth.profile.user.phone : '-'}</p>
                                        <Link to="/profil" title="Mein Profil" className="white-rounded-link py-1 px-3 rounded-pill">Mein Profil</Link>
                                    </div>
                                </div>
                            </div>:
                            <div className="row">
                                <div className="col-12 col-lg-6 px-0 px-lg-2 pe-lg-3">
                                    <div className="bg-grey border-rounded p-4 h-100">
                                        <h3>Registrierung</h3>
                                        <p>Registrieren Sie sich kostenlos in unserem Portal. Nach Prüfung Ihrer Daten  wird Ihr Zugang freigeschaltet. Bitte beachten Sie dies kann bis zu 24 Stunden dauern. Nach der Freischaltung können Sie Fälle ganz einfach an uns zur Beurteilung senden und weitere Nutzer anlegen . Einfach, effizient, sicher.<br /><br /></p>
                                        <Link to="/registrierung" title="Jetzt registrieren" className="white-rounded-link py-1 px-3 rounded-pill">zur Registrierung</Link>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 mt-4 mt-lg-0 px-0 px-lg-2 ps-lg-3">
                                    <div className="bg-grey border-rounded h-100 p-4">
                                        <h3>Login</h3>
                                        <Login />
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid my-5 py-3 py-lg-5 position-relative overflow-hidden">
            <div className="square-grey-left position-absolute bg-lightgrey"></div>
            <div className="dog-pic position-absolute">
                <img src={dogscellet} alt="Hunde-Röntgen" />
            </div>
            <div className="row">
                <div className="col-12 p-0">
                    <div className="container">
                        <div className="row">
                            <div className="offset-lg-4 col-12 col-lg-8 col-xxl-6 pt-5 px-2 px-sm-0 px-lg-3">
                                <h2>Hund, Katze, Maus - <br />MRT, CT, Röntgen oder Ultraschall</h2>
                                <p>Teleradiologie für die Veterinärmedizin. Komplizierte Fälle, komplexe Fragestellungen – es ist kaum möglich, heutzutage für jedes Fachgebiet spezialisierte Kollegen vor Ort zu haben. Unser Schwerpunkt liegt in der Erstellung von Befunden zu Ihren Bildern, egal ob es sich um Röntgen, CT oder MRT, Hund, Katze oder Maus handelt. Sie erhalten von uns einen detaillierten, auf Ihre Fragestellung zugeschnittenen Befund. Unser Credo ist „ein guter Radiologe sollte immer hilfreich sein“.</p>
                                <div className="row pt-4">
                                    <div className="col-12 col-sm-6 col-md-5 col-xl-4 info-founder text-center text-sm-start">
                                        <img src={hartmann} alt="Fr. Hartmann" />
                                    </div>
                                    <div className="col-12 col-sm-6 col-md-7 col-xl-8 align-self-center text-center text-sm-start info-founder-text pt-4 pt-sm-0 px-1 px-md-3 px-xl-5">
                                        <h4>Priv. - Doz. Dr. Antje Hartmann</h4>
                                        <p>Gründerin<br /><a href="mailto:a.hartmann@vetradiologie.de" title="E-Mail schreiben">a.hartmann@vetradiologie.de</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}