import { Link, NavLink } from "react-router-dom";
import logout from "../../hooks/logout";
import { useAuth } from "../Auth/AuthProvider";
import NotificationCenter from "../Notifications/NotificationCenter";
import logo  from "./../../assets/images/logo.svg";

export const Navigation = (props) => {
  const auth = useAuth();

  const logoutHandler = () => {
        logout().then(function(response){
          if(response)
            auth.logout();
        });
  };

  let ul =
    (auth.logged) ?
      <ul>
        <li>
          <NavLink
            to="/startseite"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            Startseite
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/dashboard"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            Mein Bereich
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/anfragen"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            Anfragen
          </NavLink>
        </li>
        { (auth.hasRight() || auth.hasRole('ACCOUNTING')) &&
          <li>
            <NavLink
              to="/rechnungen"
              className={({ isActive }) =>
                isActive ? 'active' : undefined
              }
            >
              Rechnungen
            </NavLink>
          </li>
        }
        { auth.hasRight() &&
          <li>
            <NavLink
              to="/benutzer"
              className={({ isActive }) =>
                isActive ? 'active' : undefined
              }
            >
              Benutzer
            </NavLink>
          </li>
        }
        <li>
          <NavLink
            to="/preisliste"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            Preisliste
          </NavLink>
        </li>
      </ul>
      :
      <ul>
        <li>
          <NavLink
            to="/startseite"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            Startseite
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/registrierung"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            Registrierung
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/passwort-vergessen"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            Passwort vergessen
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/faq"
            className={({ isActive }) =>
              isActive ? 'active' : undefined
            }
          >
            FAQ
          </NavLink>
        </li>
      </ul>;

  let closeMenu = () => {
    if(props.menuOpen){
        props.isMenuOpen(false);
    }
  }

  let user_nav = auth.logged && auth.profile ?
    <div className="d-flex flex-row align-items-center">
      <NotificationCenter />
      <button onClick={logoutHandler} title="Zur Startseite" className="btn d-inline logout ms-2 p-1 px-2">Logout<i className="icon-logout i-left"></i></button>
      <Link to="/profil" className="user d-sm-inline">{auth.profile.user.username}<i className="icon-person-pin i-left"></i></Link>
    </div> :
    null;

  return <div id="navarea" className="d-flex flex-row align-items-center px-sm-5 px-3 pt-3">
        <div id="logo"><Link to="/" title="Zur Startseite"><img src={logo} alt="logo" /></Link></div>
        <nav id="hnav" onClick={closeMenu} className={(props.menuOpen && 'showMenu')+" px-3"}>
            {ul}
        </nav>
        <div className="ms-auto d-flex flex-row align-items-center">
            {user_nav}
            <button title="Menü öffnen" onClick={() => {props.isMenuOpen(!props.menuOpen)}} id="show" className="ms-3"><i className={(props.menuOpen ? 'icon-cancel': 'icon-menu')+" font-blue toggleMenu "}></i></button>
        </div>
    </div>
}