import { useContext, useEffect } from "react";
import { useState, createContext } from "react";
import { me } from "../../hooks/me";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [profile, setProfile] = useState(null);
    const [logged, setLogged] = useState(null);

    const login = (profile) => {
        setLogged(true);
    };

    const logout = () => {
        setProfile(null);
        setLogged(false);
    };
    
    const hasRight = () => {
        return logged === true && profile?.user?.primary;
    }

    const hasRole = (role) => {
        if(role.length){
            if(role === profile?.user?.company_position)
                return true;
        }

        return false;
    }


    const update = () => {
        me().then((response) => {
            if(response.status){
                setLogged(true);
                setProfile(response.profile);
            }else{
                setLogged(false);
                setProfile(null);
            }
        });
    }

    useEffect(() => {
        if(logged === null ||
            (logged === true && profile === null)) {
            me().then((response) => {
                if(response.status){
                    setLogged(true);
                    setProfile(response.profile);
                }else{
                    setLogged(false);
                    setProfile(null);
                }
            });
        }
    },[logged, profile]);


    return <AuthContext.Provider value={{profile, logged, login, logout, hasRight, hasRole, update}} >
        {children}
    </AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
}