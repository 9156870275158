import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { login } from '../../hooks/login';
import { useAuth } from './AuthProvider';


export const Login = () => {
    const [responses, setResponse] = useState(null);

    const auth = useAuth();

    return auth.logged === true ? 
        <Navigate to="/dashboard"  replace="true" /> :
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={values => {
                const errors = {};
                if (!values.email) {
                    errors.email = 'Pflichtfeld';
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                    errors.email = 'Ungültige E-Mail-Addresse';
                }

                if (!values.password) {
                    errors.password = 'Pflichtfeld';
                }

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                login(values.email, values.password).then((response) => {
                    if(response.status === true) {
                        auth.login();
                        setSubmitting(true);
                    }else{
                        setSubmitting(false);
                        setResponse([response.message]);
                    }
                })
            }}
        >
            {(props) => (
                <Form>
                    {responses && 
                        <div className='messages'>
                            {responses.map((message, i) => {
                                return (<span key={i} className='error mb-2'>{message}</span>) 
                            })}
                        </div>
                    }
                    <div className="mb-3">
                        <Field type="email" name="email" placeholder="E-Mail-Adresse" className="form-control" />
                        <ErrorMessage name="email">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                    </div>
                    <div className="mb-3">
                        <Field type="password" name="password" placeholder="Passwort" className="form-control" />
                        <ErrorMessage name="password">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                    </div>
                    <button type="submit" disabled={props.isSubmitting} className="btn-white py-1 px-3 rounded-pill mt-3">
                        Anmelden
                    </button>
                    <Link to="/passwort-vergessen" className="pt-3 pt-sm-0 ms-sm-3 white-link d-block d-sm-inline">Passwort vergessen?</Link>
                </Form>
            )}
        </Formik>;
}