import { useState } from "react";
import saveMessage from "../../hooks/saveMessage";

export default function Editor({url, sent, entry, isInquiry}){
    const [message, setMessage] = useState('');
    const [responses, setResponses] = useState([]);
    const [submitted, isSubmitted] = useState(false);

    const changeMessage = (e) => {
        setMessage(e.target.value);
    }

    const validateMessage = () => {
        if(!message || !message.length)
        {
            setResponses([{type: 'error', text: 'Nachricht bitte eingeben'}]);
            return false;
        }
        return true;
    }

    const sendMessage = (e) => {
        isSubmitted(true);
        let timestamp = new Date();
        if(validateMessage())
        {
            saveMessage(url, message).then((response) => {
                if(response.status){
                    setMessage('');
                    sent(timestamp);
                }else{
                    setResponses(response.error);
                }
            }).finally(() => {
                isSubmitted(false);
            });
        }else{
            isSubmitted(false);
        }
        e.preventDefault();
    };

    return <form onSubmit={sendMessage} className="container bg-lightgrey">
                <div className="row">
                    <div className="col-12 p-0">
                        <p className="d-block bg-grey p-2 px-4">{isInquiry ? 'Anfrage Nr.' : 'Auftrag'} {isInquiry ? entry?.id : entry?.ordernumber}
                        {isInquiry ?
                            <span className="ms-3">{entry.draft.infos.pet_owner} / {entry.draft.infos.pet_name}</span>:
                            <span className="ms-3">{entry.pet_owner} / {entry.pet_name}</span>}
                        </p>
                        {responses && 
                            <div className="container messages">
                                {responses.map((entry, i) => {
                                    return (<span key={i} className={entry.type+" mb-2"}>{entry.text}</span>) 
                                })}
                            </div>
                        }
                        <div className="p-4">
                            <label htmlFor="FormControlTextarea1" className="form-label">Ihre Nachricht</label>
                            <textarea className="form-control" onChange={changeMessage} value={message} id="FormControlTextarea1" rows="5" />
                        </div>
                    </div>
                </div>
                <div className="row pb-4 px-4">
                    <div className="col-12 p-0 text-end">
                        <button className="py-1 px-3 rounded-pill btn-green" disabled={submitted}>Nachricht versenden
                        {submitted ? 
                            <span className="submitted"></span>:
                            <i className="icon-send i-left"></i>
                        }</button>
                    </div>
                </div>
            </form>
}