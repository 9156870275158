import axios from "axios";

export default async function checkUserEmail(email, except = null){
    return await axios.post('/me/users/email/check', {email: email, ...(except && {except: except})}).then((response)=>{
        if(response.status === 200)
            return {status: true};
            
        return {status: false, message: response.message};
    }).catch((error) => {
        return {status: false, message: error.response.data.message};
    });
}