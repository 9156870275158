import { Link } from "react-router-dom";

export default function AGB() {
    return <div>
        <div className="mb-3">
            <Link target={'_blank'} download className="green-rounded-link  py-1 px-3 me-sm-3 rounded-pill" to="/agb.pdf">Download</Link>
        </div>
        <p>Unter der Internet-Domain <a href="vetradiologie.app">vetradiologie.app</a> betreibt Frau PD Dr. Antje Hartmann, Inhaberin der Vetradiologie (nachstehend „<strong><em>Vetradiologie</em></strong>“),
            ein Online-Portal (nachstehend das „<strong><em>Portal</em></strong>“). Auf diesem Portal können approbierte Tierärzte*innen,
            Tierarztpraxen, Tierkliniken und sonstige praktizierende Veterinärmediziner*innen (nachstehend die „<strong><em>Kunden</em></strong>“)
            für ihre eigene unternehmerische Tätigkeit eine tierärztliche radiologische Fachauskunft (im nachstehend die „<strong><em>Fachauskunft/ Fachauskünfte</em></strong>") erhalten. <br /> <br />
            Zur besseren Lesbarkeit wird im nachfolgenden Text das generische Maskulinum verwendet. Gemeint sind jedoch immer alle Geschlechter.
        </p>

        <h2>1. Geltungsbereich dieser Geschäftsbedingungen</h2>
        <p>Diese Geschäftsbedingungen gelten für sämtliche Geschäftsbeziehungen zwischen Vetradiologie und dem Kunden,
            die unter Verwendung des Portals abgeschlossen und/oder abgewickelt werden. Abweichende oder ergänzende allgemeine
            Geschäftsbedingungen des Kunden werden nicht Vertragsbestandteil. <br />
            Der Kunde ist dafür verantwortlich und stellt sicher, dass auch seine Mitarbeiter, unabhängig davon, ob diese einen eigenen
            Zugang zu dem Portal erhalten, die Vorgaben dieser Geschäftsbedingungen einhalten.
        </p>

        <h2>2. Zugang zu dem Portal; Vertragsabschluss</h2>
        <p>2.1  Der Kunde registriert sich zunächst auf dem Portal unter Angabe von Praxis- oder Klinikdaten sowie einer primär verantwortlichen,
            natürlichen Person als Hauptbenutzer. Die Freischaltung des Hauptbenutzerkontos erfolgt nach Prüfung der Eingaben des Kunden durch Vetradiologie.
            Nach Freischaltung des Hauptbenutzerkontos kann der Kunde bei Bedarf weitere Zugänge für seine Mitarbeiter anlegen. <br /> <br />

            2.2  Der Kunde ist verpflichtet, das Portal ausschließlich unter Verwendung der ihm von der Vetradiologie zugeteilten Zugangsdaten und der von ihm bzw.
            seinen Mitarbeitern festgelegten Passwörter zu nutzen; die Zugangsdaten und Passwörter sind streng vertraulich zu behandeln und jede unberechtigte Kenntnisnahme
            und/oder Nutzung durch Dritte ist von dem Kunden zu verhindern. Der Kunde hat Vetradiologie jede auch nur mögliche unberechtigte Kenntnisnahme und/oder Nutzung der
            Zugangsdaten und/oder Passwörter umgehend zu melden. Im Falle eines (möglichen) Missbrauchs von Zugangsdaten und/oder Passwörtern ist Vetradiologie berechtigt,
            den Zugang zu dem Portal so lange zu sperren, bis der Sachverhalt aufgeklärt und der Missbrauch abgestellt ist. <br /> <br />

            2.3  Die Bereitstellung des Portals und das Freischalten des Hauptbenutzerkontos stellen noch kein Angebot von Vetradiologie dar. Erst mit dem Hochladen bzw. der Übersendung
            von Patientendaten unterbreitet der Kunde Vetradiologie ein Angebot, das Vetradiologie frei ist anzunehmen oder abzulehnen. Nimmt Vetradiologie das Angebot an, erhält der Kunde
            eine gesonderte Auftragsnummer, womit der Vertrag zwischen den Parteien zustande gekommen ist

            2.4	Aussagen von Vetradiologie zu dem Portal in Werbungen, auf anderen Internetauftritten oder in Dokumentationen sind lediglich beschreibender Natur und stellen keine Garantie
            oder Zusicherung einer Eigenschaft dar.<br /> <br />
        </p>

        <h2>3. Mitwirkungspflichten des Kunden</h2>
        <p>3.1 Der Kunde ist für die Bereitstellung der technischen Voraussetzungen für den Zugang zu dem Portal verantwortlich, insbesondere hinsichtlich der von ihm eingesetzten Hard- und Software,
            des Betriebssystems und der Verbindung zum Internet. Soweit das Portal technisch notwendige Cookies verwendet, ist der Kunde verpflichtet, diese zu akzeptieren.<br /> <br />

            3.2 Im Falle der Weiterentwicklung des Portals ist der Kunde dafür verantwortlich, bei Bedarf die von ihm eingesetzte technische Infrastruktur anzupassen.<br /> <br />

            3.3 Die Sicherung seiner Systeme und Daten obliegt dem Kunden. Der Kunde ist zudem verpflichtet dafür zu sorgen, dass die von ihm über das Portal hochgeladenen
            Daten frei sind von jedweder Schadsoftware.<br /><br />

            3.4	Der Kunde ist verpflichtet, sämtliche Vorgaben von Vetradiologie betreffend die Nutzung des Portals einzuhalten, insbesondere auch bezüglich der Größe und der
            Dateitypen für hochzuladende Daten.<br /><br />

            3.5	Der Kunde ist verpflichtet, sowohl die Inhalte der von ihm übermittelten Daten vor dem Hochladen als auch die Fachauskünfte von Vetradiologie vor einer weiteren
            Verwendung bzw. Weitergabe einer Plausibilitätskontrolle zu unterziehen.<br /><br />

            .6	Der Kunde ist verpflichtet, Vetradiologie auftretende Mängel, Störungen oder Schäden im Zusammenhang mit dem Portal unverzüglich anzuzeigen.<br /><br />

            3.7	Der Kunde ist verpflichtet, Nachfragen von Vetradiologie zeitnah zu beantworten.
        </p>

        <h2>4. Pflichten von Vetradiologie; Weiterentwicklung des Online-Portals</h2>
        <p>4.1 Nach Fertigstellung stellt Vetradiologie die Fachauskunft dem Kunden über das Portal zur Verfügung und informiert den Kunden hierüber.
            Der Kunde kann die Fachauskunft über das Portal einsehen und herunterladen. <br /><br />

            4.2	Vetradiologie beabsichtigt, das Portal weiterzuentwickeln und an die jeweils aktuellen Anforderungen anzupassen. Vetradiologie behält sich
            deshalb die Anpassung des Portals an den Stand der Technik und Änderungen des Portals insbesondere zur Optimierung der Nutzerfreundlichkeit vor.<br /><br />

            4.3	Vetradiologie ist berechtigt, für die Erfüllung von Aufträgen neben den eigenen Angestellten freie Mitarbeiter und Subunternehmer einzusetzen,
            wobei Vetradiologie für die ordnungsgemäße Erfüllung der vertraglichen Leistungen gegenüber dem Kunden verantwortlich bleibt.
        </p>

        <h2>5. Vergütung, Zahlungsverzug</h2>
        <p>5.1	Die von dem Kunden über das Portal beauftragten Leistungen sind gemäß der jeweils unter <a href="vetradiologie.app">vetradiologie.app</a>  angegebenen und im Zeitpunkt der
            Beauftragung gültigen Preisliste zu vergüten.<br /><br />

            5.2	Soweit sie nicht ausdrücklich als Bruttopreise bezeichnet sind, verstehen sich die Preise jeweils zuzüglich der bei Beauftragung geltenden gesetzlichen Umsatzsteuer.<br /><br />

            5.3	Vetradiologie übersendet dem Kunden die Rechnungen per E-Mail, womit sich der Kunde ausdrücklich einverstanden erklärt. Rechnungen der Vetradiologie sind von dem Kunden innerhalb von vierzehn
            Kalendertagen durch Überweisung auf das in der Rechnung benannte Geschäftskonto zu bezahlen<br /><br />

            5.4	Ist der Kunde mit mindestens zwei aufeinander folgenden Rechnungen für den Abruf von Fachauskünften in Verzug, so hat Vetradiologie das Recht, den Zugang des Kunden und seiner Mitarbeiter zu dem Portal zu sperren,
            bis der Zahlungsrückstand vollständig beglichen wurde; weitere Rechte von Vetradiologie bleiben hiervon unberührt.
        </p>

        <h2>6. Nutzungsrechte des Kunden</h2>
        <p>6.1	Alle Fachauskünfte von Vetradiologie sind urheberrechtlich geschützt, und der Kunde verpflichtet sich zur Einhaltung dieser Schutzrechte.<br /><br />

            6.2	Unter der Bedingung der Zahlung der fälligen Vergütung für die jeweilige Fachauskunft erhält der Kunde das einfache, nicht ausschließliche, nicht übertragbare, zeitlich unbegrenzte Recht zur Nutzung der von
            Vetradiologie erstellten Fachauskunft zu eigenen Zwecken. Dieses Recht beinhaltet die Verwendung der Fachauskunft gegenüber dem jeweiligen Tierhalter und berechtigt den Kunden, die Fachauskunft zu lesen, herunterzuladen
            und auf seinem Rechner abzuspeichern und auszudrucken. Fachauskünfte dürfen von dem Kunden nur für den Einzelfall verwendet und nur dem jeweiligen Tierhalter zugänglich gemacht werden. Eine darüberhinausgehende Vervielfältigung bzw.
            Verwertung der Fachauskunft ist nur mit vorhergehender schriftlicher Zustimmung von Vetradiologie zulässig. <br /><br />

            6.3	Ein systematischer, automatisierter Abruf von Fachauskünften, das Erstellen von Sammlungen aus abgerufenen Fachauskünften und die systematische Weitergabe oder Zugänglichmachung von Fachauskünften aus dem Portal sind unzulässig. <br /><br />
        </p>

        <h2>7. Datenschutzrechtliche Einwilligungen; Nutzungsrecht an Bildern; Vertraulichkeit</h2>
        <p>7.1	Der Kunde ist für die Einholung eventueller datenschutzrechtlicher Einwilligungen der Besitzer der Patienten betreffend die Verarbeitung, insbesondere auch die Weitergabe der Bilder und der Patientendaten an Vetradiologie,
            deren Angestellte und freie Mitarbeiter bzw. Subunternehmer verantwortlich. Der Kunde ist damit einverstanden, dass die Vetradiologie im Rahmen eines Auftrages überlassenen Bilder und Patientendaten von Vetradiologie,
            ihren Angestellten und freien Mitarbeitern bzw. Subunternehmer in anonymisierter Form für Vorträge, Lehrveranstaltungen und die Erstellung entsprechender Unterlagen verwendet werden. Der Kunde sichert zu, dass er, soweit erforderlich,
            auch diesbezüglich die entsprechende Einwilligung der Besitzer seiner Patienten eingeholt hat. <br /><br />

            7.2	Der Kunde sichert zu, dass er Inhaber der umfassenden Nutzungsrechte an den Vetradiologie überlassenen Bildern ist. Er räumt Vetradiologie, ihren Angestellten und freien Mitarbeitern bzw. Subunternehmer an den ihr im Rahmen eines
            Auftrages überlassenen Bildern ein zeitlich und räumlich unbeschränktes Recht zur Nutzung für Vorträge, Lehrveranstaltungen und die Erstellung entsprechender Unterlagen ein, einschließlich der damit einhergehenden Vervielfältigung,
            Verbreitung und öffentlichen Zugänglichmachung.
        </p>

        <h2>8. Haftung von Vetradiologie</h2>
        <p>8.1	Vetradiologie haftet bei Vorsatz und grober Fahrlässigkeit unbeschränkt.<br /><br />

            8.2	Bei einfacher Fahrlässigkeit haftet Vetradiologie nur für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie für Schäden aus der nicht unerheblichen Verletzung einer wesentlichen Vertragspflicht
            (das ist eine Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages mit dem Kunden überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertraut und vertrauen darf); in letzterem Fall
            ist die Haftung von Vetradiologie jedoch auf den Ersatz des bei Vertragsschluss vorhersehbaren, typischerweise eintretenden Schadens begrenzt. <br />
            Vetradiologie haftet zudem lediglich für die Befundung der von dem Kunden beauftragten Körperregionen und insbesondere nicht für eventuelle Befunde, die sich außerhalb der beauftragten Körperregionen befinden.<br /><br />

            8.3 Gewährleistungsansprüche und Schadensersatzansprüche gegen Vetradiologie verjähren nach Ablauf eines Jahres, es sei denn, es handelt sich um Ansprüche wegen einer Pflichtverletzung gemäß Ziffer 8.1 oder um Schadensersatzansprüche
            gemäß Ziffer 8.2 aus der Verletzung des Lebens, des Körpers oder der Gesundheit. <br /><br />

            8.4	Der Kunde hat Vetradiologie etwaige Schäden unverzüglich anzuzeigen.
        </p>

        <h2>Personenbezogene Daten des Kunden; Allgemeines</h2>
        <p>9.1 	Der Kunde wird darauf hingewiesen, dass Vetradiologie personenbezogene Daten im Rahmen der Zweckbestimmung des Vertragsverhältnisses verarbeitet. Vetradiologie verpflichtet sich zur Einhaltung der datenschutzrechtlichen Vorgaben.<br /><br />

            9.2	Erfüllungsort und ausschließlicher Gerichtsstand ist der Sitz von Vetradiologie. <br /><br />

            9.3 Alle Streitigkeiten im Zusammenhang mit dem Abschluss, der Durchführung und/oder der Beendigung eines Vertrages zwischen den Parteien, auf den diese Geschäftsbedingungen Anwendung finden, unterliegen dem deutschen materiellen Recht
            unter Ausschluss des UN-Kaufrechts. <br /><br />
        </p>
    </div>
}