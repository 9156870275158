import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import checkUserEmail from "../../hooks/checkUserEmail";
import getUser from "../../hooks/getUser";
import getUserSets from "../../hooks/getUserSets";
import saveUser from "../../hooks/saveUser";
import { Loading } from "../App/Loading";

export default function UserForm(props){
    const [response, setResponse] = useState({});
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sets, setSet] = useState(null);
    const [mode, setMode] = useState(props.mode);
    const [emailCache, setEmailCache] = useState(null);
    const [tooltipOpen, isTooltipOpen] = useState(true);
    const params = useParams();
    const message = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if(mode === 'edit' && !user) {
            let id = params.userId;
            getUser(id).then((response)=> {
                if(response.status){
                    setUser(response.user);
                }else{
                    navigate('/benutzer', {replace:true, state: {
                        message: {type: 'error', title: 'Benutzer nicht gefunden', description: 'Benutzer mit ID '+id+' konnte nicht gefunden werden'}
                    }});
                    setUser(null);
                }
            }).catch(() => {
                setMode('new');
                setResponse({isOpen: true, type: 'error', title: 'Benutzer nicht gefunden', description: 'Benutzer mit ID '+id+' konnte nicht gefunden werden'});
                setTimeout(() => {
                    setResponse({...response, isOpen:false});
                }, 4000);
                setUser(null);
            });
        }

        if(loading && !sets){
            getUserSets().then((response) => {
                if(response.status){
                    setSet(response.sets);
                    setLoading(false);
                }
            })
        }
    },[mode, user, loading, sets]);

    async function validateEmail(email) {
        if (!email) {
            return 'Pflichtfeld';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
        ){
            return 'Ungültige E-Mail-Addresse';
        }else if(emailCache !== email){
            return await checkUserEmail(email,(mode === 'edit' && user.id) ?? null).then((respons) => {
                if(respons.status){
                    setEmailCache(email);
                    return null;
                }else{
                    return respons.message;
                }
            });
        }else{
            return null;
        }
    };

    return loading ?
    <Loading />  :
    <>
    {message.length &&
    <Toast isOpen={tooltipOpen ? true : false} className='position-absolute' style={{top: '10px', left: "calc(50% - 175px)"}}>
        <ToastHeader icon={<i className={"icon "+(message.type === 'success' ? 
                                                    "icon-check text-success" : 
                                                    "icon-cancel text-danger")}>
                            </i>}>
            {message.title}
        </ToastHeader>
        <ToastBody>
            {message.description}
        </ToastBody>
    </Toast>}
    <Toast isOpen={response && response.isOpen ? true : false} className='position-absolute' style={{top: '10px', left: "calc(50% - 175px)"}}>
        <ToastHeader icon={<i className={"icon "+(response.type === 'success' ? 
                                                    "icon-check text-success" : 
                                                    "icon-cancel text-danger")}></i>}>
            {response?.title}
        </ToastHeader>
        <ToastBody>
            {response?.description}
        </ToastBody>
    </Toast>
    <Formik
        enableReinitialize
        initialValues={{
            title: user?.title ?? '',
            address: user?.address ?? '',
            firstname: user?.firstname ?? '',
            lastname: user?.lastname ?? '',
            email: user?.email ?? '',
            phone: user?.phone ?? '',
            company_position: user?.company_position ?? ''
        }}
        validate={values => {
            const errors = {};
            if (!values.firstname) {
                errors.firstname = 'Pflichtfeld';
            }

            if (!values.lastname) {
                errors.lastname = 'Pflichtfeld';
            }

            if (!values.address) {
                errors.address = 'Pflichtfeld';
            }

            if(!values.company_position)
                errors.company_position = 'Pflichtfeld';
            if(!(values.company_position in sets.company_positions))
                errors.companyPositions =  'Kein gültige Anrede ausgewählt';

            return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
            saveUser(mode,user?.id,values).then((response)=>{
                if(response.status){
                    setSubmitting(false);
                    if(response.mode === 'created'){
                        navigate('/benutzer/'+response.id, {replace:false, state: [{type: 'success', title: 'Neuer Benutzer angelegt', description: 'Neuer Benutzeraccount mit der ID '+response.id+' wurde angelegt'}] });
                        navigate(0);
                    }else{
                        setResponse({isOpen: true, type:'success',title:'Änderungen gespeichert', description: 'Änderung gespeichert und Benutzeraccount aktualisiert'});
                        setTimeout(() => {
                            setResponse({...response, isOpen:false});
                        }, 4000);
                    }
                }else{
                    setResponse({...response.error, isOpen:true});
                    setTimeout(() => {
                        setResponse({...response, isOpen:false});
                    }, 4000);
                    setSubmitting(false);
                }
            });
        }}
    >
        {(props) => (
            <Form method="POST">
                <h2 className="my-3">{mode === 'edit' ? 'Benutzer bearbeiten': 'Neuen Benutzer anlegen'}</h2>
                {mode === 'edit' && !user ?
                     <div className="row">
                     <div className="col-md-6 col-12">
                         <div className="form-group">
                             <label htmlFor="title">Titel</label>
                             <div className="placeholder-loading"></div>
                         </div>
                     </div>
                     <div className="col-md-6 col-12">
                         <div className="form-group mt-md-0 mt-3">
                             <label htmlFor="address">Anrede</label>
                             <div className="placeholder-loading"></div>
                         </div>
                     </div>
                     <div className="col-md-6 col-12">
                         <div className="form-group mt-3">
                             <label htmlFor="firstname">Vorname</label>
                             <div className="placeholder-loading"></div>
                         </div>
                     </div>
                     <div className="col-md-6 col-12">
                         <div className="form-group mt-3">
                             <label htmlFor="lastname">Nachname</label>
                             <div className="placeholder-loading"></div>
                         </div>
                     </div>
                     <div className="col-md-6 col-12">
                         <div className="form-group mt-3">
                             <label htmlFor="email">E-Mail-Adresse</label>
                             <div className="placeholder-loading"></div>
                         </div>
                     </div>
                     <div className="col-md-6 col-12">
                         <div className="form-group mt-3">
                             <label htmlFor="phone">Telefonnummer</label>
                             <div className="placeholder-loading"></div>
                         </div>
                     </div>
                 </div>
                :
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <div className="form-group">
                                <label htmlFor="title">Titel</label>
                                <Field type="text" name="title" placeholder="Titel" className="form-control" />
                                <ErrorMessage name="title">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group mt-md-0 mt-3">
                                <label htmlFor="address">Anrede*</label>
                                <Field as="select" name="address" placeholder="Anrede" className="form-select" >
                                    <option value=''>Bitte auswählen</option>
                                    <option value='FEMALE'>Frau</option>
                                    <option value='MALE'>Herr</option>
                                    <option value='DIVERS'>Divers</option>
                                </Field>
                                <ErrorMessage name="address">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group mt-3">
                                <label htmlFor="firstname">Vorname*</label>
                                <Field type="text" name="firstname" placeholder="Vorname" className="form-control" />
                                <ErrorMessage name="firstname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group mt-3">
                                <label htmlFor="lastname">Nachname*</label>
                                <Field type="text" name="lastname" placeholder="Nachname" className="form-control" />
                                <ErrorMessage name="lastname">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group mt-3">
                                <label htmlFor="email">E-Mail-Adresse*</label>
                                <Field type="email" name="email" placeholder="E-Mail-Adresse" className="form-control" validate={validateEmail} />
                                <ErrorMessage name="email">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group mt-3">
                                <label htmlFor="phone">Telefonnummer</label>
                                <Field type="phone" name="phone" placeholder="Telefonnummer" className="form-control" />
                                <ErrorMessage name="phone">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group mt-3">
                                <label htmlFor="company_position" className="form-label">Aufgabenbereich*</label>
                                <Field component="select" className="form-select" name="company_position" id="company_position" required>
                                        <option value=''>Bitte auswählen</option>
                                        { Object.entries(sets.company_positions).map((val, key) => {
                                        return (<option key={val[0]} value={val[0]}>{val[1]}</option>)
                                    })}
                                </Field>
                                <ErrorMessage name="company_position">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                        </div>
                    </div>
                }
                <div className="d-flex flex-row align-items-center justify-content-end mt-3">
                    <Link to="/benutzer" title="Benutzerübersicht" className="py-1 px-3 rounded-pill btn-grey me-2"><i className="icon-left-circle i-right me-1"></i>Zurück</Link>
                    <button type="submit" disabled={props.isSubmitting || props.isValid !== true} className="py-1 px-3 rounded-pill btn-green">{mode === 'edit' ? 'Aktualisieren' : 'Hinzufügen'}</button>
                </div>
            </Form>
        )}
    </Formik>
    </>
}