import axios from "axios";

export default async function getHistory(link){
    return await axios.get(link + "/history").then((response) => {
        if(response.status === 200 && response.data)
            return {history: response.data, latestId: response.data[0].id};

        return null;
    }).catch(() => {
        return null;
    });
}