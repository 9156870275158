import axios from "axios";

export default async function deleteUpload(link, id){
    return await axios.delete(link+'/attachments/uploads/'+id).then((response) => {
        if(response.status === 204)
            return true;

        return false;
    }).catch(() => {
        return false;
    });
}