import axios from "axios";

export default async function logout() {
    return await axios.post('/logout').then((response) => {
        if(response.status === 204)
            return true;

        return false;
    }).catch(() => {
        return false;
    });
}