import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import saveAnalysis from "../../../hooks/saveAnalysis";

export default function Analysis(props){
    const [responses, setResponse] = useState(null);
    let backward = props.backward;

    return (
        <Formik
            initialValues={
                {
                    anamnesis: props?.inquiry?.anamnesis ?? '',
                    clinical_diagnostic: props?.inquiry?.clinical_diagnostic ?? '',
                    question: props?.inquiry?.question ?? ''
                }
            }
            validate={values => {
                let errors = {};
                if(!values.anamnesis)
                    errors.anamnesis = 'Bitte die Anamnese angeben';

                return errors;
            }}
            onSubmit={(values, {setSubmitting}) => {
                setSubmitting(false);

                // Save analysis
                saveAnalysis(props.url, values).then((response) => {
                    if(response.status){
                        // worked & move on
                        setSubmitting(true);
                        props.forward();
                    }else{
                        // show error message
                        setResponse(response.error);
                    }
                });
            }}
        >
        {(props) => (
                <Form>
                    <div className="container bg-lightgrey mt-3 mb-0 p-4 px-4 px-sm-5 step03">
                        <div className="row justify-content-center">
                            <h2 className="font-green"><i className="icon-info"></i>3. Klinische Angaben</h2>
                            {responses && 
                                <div className="messages">
                                    {responses.map((message, i) => {
                                        return (<span key={i} className={message.type+" mb-2"}>{message.text}</span>) 
                                    })}
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="mb-3 col-12 pr-5 pt-0 pt-md-3">
                                <label htmlFor="anamnesis" className="form-label">Anamnese*</label>
                                <Field component="textarea" className="form-control" id="anamnesis" name="anamnesis" rows="5" required />
                            <ErrorMessage name="anamnesis">{msg => <span className='error'>{msg}</span>}</ErrorMessage>
                            </div>
                            <div className="mb-3 col-12 pr-5 pt-0 pt-md-3">
                                <label htmlFor="clinical_diagnostic" className="form-label">Klinische Befunde</label>
                                <Field component="textarea" className="form-control" id="clinical_diagnostic" name="clinical_diagnostic" rows="5" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-12 pr-5 pt-0 pt-md-2">
                                <label htmlFor="question" className="form-label">Fragestellung</label>
                                <Field component="textarea" className="form-control" id="question" name="question" rows="5" />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center text-sm-end pt-3">
                                <button type="button" onClick={backward} className="py-1 px-3 rounded-pill btn-grey mb-3"><i className="icon-left-circle i-right"></i>Zurück</button>
                                <button type="submit" className="py-1 px-3 rounded-pill btn-green ms-2 mb-3">Speichern</button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}