import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Toast, ToastBody, ToastHeader } from "reactstrap";
import getInquiry from "../../hooks/getInquiry";
import { Loading } from "../App/Loading";
import Status from "./Status";
import Analysis from "./Steps/Analysis";
import Files from "./Steps/Files";
import Infos from "./Steps/Infos";
import Overview from "./Steps/Overview";
import Regions from "./Steps/Regions";

export function UpdateForm(){
    const [step, setStep] = useState(1);
    const [message, setMessage] = useState({isOpen: false});
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    let id = params.id
    let baseUrl = '/me/inquiries';

    useEffect(() => {
        if(location.state?.message){
            setStep(2);
            setMessage({...location.state.message, isOpen: true})
            setTimeout(() => {
                setMessage({...message, isOpen: false});
            },4000);
        }
    },[location]);
    
    const { isLoading, isError, error, data, refetch, dataUpdatedAt }  = useQuery({ 
                                                            queryKey: ['inquiry', id], 
                                                            queryFn: async() => await getInquiry(baseUrl, id),
                                                            retry: false
                                                        })
    if(isLoading)
        return <Loading /> 

    if(isError)
        throw error;

    function cancel(){
        navigate('/anfragen');
    }

    function backward(){
        if(step !== 1)
            setStep(step-1);
    }

    function forward(){
        refetch();
        if(step !== 5)
            setStep(step+1);
    }

    function goto(step){
        setStep(step);
    }

    function finished(){
        navigate('/anfragen');
    }

    var form = (function() {
        switch (step) {
            case 1:
                return <Infos edit={true} baseUrl={baseUrl} forward={forward} cancel={cancel} id={id}  inquiry={data.infos} />;
            case 2:
                return <Regions url={baseUrl+'/'+id} backward={backward} forward={forward} id={id} inquiry={data.regions} contrast_medium={data.infos.contrast_medium}/>;
            case 3:
                return <Analysis url={baseUrl+'/'+id} backward={backward} forward={forward} inquiry={data.analysis} />;
            case 4:
                return <Files url={baseUrl+'/'+id} backward={backward} forward={forward} inquiry={data.files} />;
            case 5:
                return <Overview url={baseUrl+'/'+id} finished={finished} backward={backward} goto={goto}  inquiry={data}/>;
            default:
                return <Navigate to="/anfragen" />;
        }
    })();

    return <>
        <Toast isOpen={message.isOpen ? true : false} className='position-absolute' style={{top: '10px', left: "calc(50% - 175px)"}}>
            <ToastHeader icon={<i className={"icon "+(message?.type === 'success' ? 
                                                        "icon-check text-success" : 
                                                        "icon-cancel text-danger")}>
                                </i>}>
                {message.title ?? ''}
            </ToastHeader>
            <ToastBody>
                {message.description ?? ''}
            </ToastBody>
        </Toast>
        <h2 className="mb-5">Anfrage bearbeiten</h2>
        <Status step={step} />
        {form}
    </>
}